import { useState, useMemo } from 'react';
import { StudySetCard } from 'stores/studySet';

type Order = 'asc' | 'desc' | 'none';

interface SortConfig {
  key: keyof StudySetCard | 'additions';
  direction: Order;
}

export const useSortableData = (items: StudySetCard[], config?: SortConfig) => {
  const [sortConfig, setSortConfig] = useState<SortConfig | undefined>(config);

  const sortedItems = useMemo(() => {
    if (sortConfig && sortConfig.direction !== 'none') {
      return [...items].sort((a, b) => {
        if (sortConfig.key === 'additions') {
          const compare = (aVal: boolean, bVal: boolean) => {
            if (aVal === bVal) return 0;
            return aVal ? -1 : 1;
          };
          const result = compare(a.hasDefinitions, b.hasDefinitions);
          if (result !== 0) return sortConfig.direction === 'asc' ? result : -result;
          return sortConfig.direction === 'asc'
            ? compare(a.hasExamples, b.hasExamples)
            : compare(b.hasExamples, a.hasExamples);
        } else if (sortConfig.key === 'learningLevel') {
          return sortConfig.direction === 'asc'
            ? a[sortConfig.key] - b[sortConfig.key]
            : b[sortConfig.key] - a[sortConfig.key];
        } else {
          const aTerms = (a[sortConfig.key] as string[]).join(' ');
          const bTerms = (b[sortConfig.key] as string[]).join(' ');
          return sortConfig.direction === 'asc'
            ? aTerms.localeCompare(bTerms)
            : bTerms.localeCompare(aTerms);
        }
      });
    }
    return items;
  }, [items, sortConfig]);

  const requestSort = (key: keyof StudySetCard | 'additions') => {
    let direction: Order = 'asc';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    } else if (sortConfig && sortConfig.key === key && sortConfig.direction === 'desc') {
      direction = 'none';
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};
