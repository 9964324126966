import { Typography, Tooltip, Badge, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Trophy } from 'stores/user';

interface TrophyItemProps {
  trophy: Trophy;
}

export function TrophyItem({ trophy }: TrophyItemProps) {
  const { t } = useTranslation();
  const badgeContent = trophy.number && trophy.number > 1 ? trophy.number : 0;
  return (
    <Grid item xs={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Badge badgeContent={badgeContent} color="error">
        <Tooltip
          leaveTouchDelay={5000}
          enterTouchDelay={0}
          title={t('trophies.trophy_description', {
            name: trophy.info.name,
            rarity: Math.trunc(trophy.info.rarity * 100),
          })}
          arrow
        >
          <img src={trophy.info.url} alt={trophy.info.name} width="50" height="50" />
        </Tooltip>
      </Badge>
      <Typography variant="caption" align="center" sx={{ textTransform: 'capitalize' }}>
        {t('trophies.trophy_title')}
        <br />({trophy.info.name})
      </Typography>
    </Grid>
  );
}
