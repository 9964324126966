import { graphql } from 'services/graphql';

export const updateIntervalTrainingSettingsMutation = graphql`
  mutation UpdateIntervalTrainingSettings(
    $trainingEnabled: Boolean
    $interval: Int
    $startTime: String
    $endTime: String
    $timezoneOffset: Int
  ) {
    updateIntervalTrainingSettings(
      trainingEnabled: $trainingEnabled
      interval: $interval
      startTime: $startTime
      endTime: $endTime
      timezoneOffset: $timezoneOffset
    ) {
      isSuccess
      errors {
        code
      }
    }
  }
`;

export const updateUserSettingsMutation = graphql`
  mutation UpdateUserSettings($language: String) {
    updateUserSettings(language: $language) {
      isSuccess
      errors {
        code
        field
      }
    }
  }
`;
