import { TextField, Button, IconButton } from '@mui/material';
import { ArrowDownward, ArrowUpward, UnfoldMore, Search, Clear } from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';

interface FilterSortControlsProps {
  filter: string;
  sortOrder: 'asc' | 'desc' | 'none';
  isOwner: boolean;
  setFilter: (value: string) => void;
  onSortOrderChange: () => void;
}

export function FilterSortControls({
  filter,
  sortOrder,
  isOwner,
  setFilter,
  onSortOrderChange,
}: FilterSortControlsProps) {
  const { t } = useTranslation();
  const textFieldRef = useRef<HTMLInputElement>(null);

  const getSortIcon = () => {
    switch (sortOrder) {
      case 'asc':
        return <ArrowUpward />;
      case 'desc':
        return <ArrowDownward />;
      case 'none':
      default:
        return <UnfoldMore />;
    }
  };

  const handleFocus = () => {
    setTimeout(() => {
      if (textFieldRef.current) {
        textFieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 500);
  };

  return (
    <Grid container spacing={2} sx={{ my: 2 }}>
      <Grid xs={12} sm={isOwner ? 9 : 12} md={isOwner ? 10 : 12}>
        <TextField
          placeholder={t('common.search')}
          value={filter}
          variant="outlined"
          size="small"
          autoComplete="off"
          fullWidth
          inputRef={textFieldRef}
          onChange={(e) => setFilter(e.target.value)}
          onFocus={handleFocus}
          InputProps={{
            startAdornment: <Search sx={{ mr: 1 }} />,
            endAdornment: filter && (
              <IconButton edge="end" onClick={() => setFilter('')}>
                <Clear color="primary" />
              </IconButton>
            ),
          }}
        />
      </Grid>
      {isOwner && (
        <Grid xs={12} sm={3} md={2}>
          <Button variant="outlined" endIcon={getSortIcon()} onClick={onSortOrderChange}>
            {t('profile_sets.sorting_progress')}
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
