import { create } from 'zustand';
import { NotificationTypes } from './app.types';

interface AppState {
  serviceMessage: {
    messageCode: null | string;
    isShown: boolean;
  };
  notification: {
    isShown: boolean;
    type: NotificationTypes;
    message?: string;
  };
}

interface AppActions {
  setServiceMessageCode: (code: string | null) => void;
  setNotificationBar: (status: NotificationTypes, message?: string) => void;
  closeNotificationBar: () => void;
}

const initialState: AppState = {
  serviceMessage: {
    messageCode: null,
    isShown: false,
  },
  notification: {
    isShown: false,
    type: 'success',
    message: undefined,
  },
};

export const useAppStore = create<AppState & AppActions>((set) => ({
  ...initialState,
  setServiceMessageCode: (code) =>
    set((state) => ({
      serviceMessage: { ...state.serviceMessage, messageCode: code, isShown: code !== null },
    })),
  setNotificationBar: (status, message) => {
    set({ notification: { isShown: true, type: status, message } });
  },
  closeNotificationBar: () =>
    set((state) => ({ notification: { ...state.notification, isShown: false } })),
}));
