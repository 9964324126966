import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Typography,
  Switch,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useStudySetStore, Visibility } from 'stores/studySet';
import { ROUTES } from 'routes/routes.config';
import { ProfileRouteParams } from 'routes/routes.types';
import { STUDY_SET_NAME_LENGTH_LIMIT } from 'constants/index';

interface AddSetDialogProps {
  open: boolean;
  onClose: () => void;
}

export function AddSetDialog({ open, onClose }: AddSetDialogProps) {
  const [studySetName, setStudySetName] = useState('');
  const [isPublic, setIsPublic] = useState(true);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userId } = useParams() as ProfileRouteParams;
  const { create } = useStudySetStore();

  const handleSubmit = async () => {
    const visibility = isPublic ? Visibility.public : Visibility.private;
    const newSetId = await create(studySetName.trim(), visibility);
    if (newSetId) {
      onClose();
      navigate(ROUTES.STUDY_SET(userId, newSetId as string));
    }
  };

  return (
    <Dialog fullWidth sx={{ top: { xs: '-30vh', sm: 0 } }} open={open} onClose={onClose}>
      <DialogTitle>{t('dialog_creating_new_set.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('dialog_creating_new_set.text')}</DialogContentText>
        <TextField
          autoFocus
          variant="outlined"
          margin="dense"
          label={t('dialog_creating_new_set.input_label')}
          autoComplete="off"
          fullWidth
          inputProps={{ maxLength: STUDY_SET_NAME_LENGTH_LIMIT }}
          value={studySetName}
          onChange={(e) => setStudySetName(e.target.value)}
        />
        <Typography variant="body1" mt={2}>
          {t('dialog_edit_set.title_public')}
        </Typography>
        <Switch name="isPublic" checked={isPublic} onChange={() => setIsPublic(!isPublic)} />
        <Typography display="inline" color="text.secondary">
          {isPublic ? t('dialog_edit_set.title_public_yes') : t('dialog_edit_set.title_public_no')}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ p: 3, pt: 0 }}>
        <Button variant="contained" color="secondary" onClick={onClose}>
          {t('common.close')}
        </Button>
        <Button disabled={!studySetName.trim()} variant="contained" onClick={handleSubmit}>
          {t('common.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
