import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAuthStore } from 'stores/auth';
import { useStudySetStore } from 'stores/studySet';
import { ROUTES } from 'routes/routes.config';

interface JoinStudySetDialogProps {
  setId: string;
  open: boolean;
  onClose: () => void;
  onJoined: () => void;
}

export function JoinStudySetDialog({ setId, open, onClose, onJoined }: JoinStudySetDialogProps) {
  const [loading, setLoading] = useState(false);
  const [joinedStudySetId, setJoinedStudySetId] = useState<string | null>(null);
  const { user } = useAuthStore();
  const { t } = useTranslation();
  const { join } = useStudySetStore();

  const handleSubmit = async () => {
    setLoading(true);
    const newSetId = await join(setId);
    setLoading(false);
    if (newSetId) {
      setJoinedStudySetId(newSetId);
      onJoined();
    }
  };

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>
        {joinedStudySetId ? t('dialog_join_set.title_success') : t('dialog_join_set.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {joinedStudySetId ? t('dialog_join_set.text_success') : t('dialog_join_set.text')}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: 3, pt: 0 }}>
        <Button variant="contained" color="secondary" onClick={onClose}>
          {t('common.close')}
        </Button>
        {joinedStudySetId ? (
          <Button
            variant="contained"
            component={Link}
            to={ROUTES.STUDY_SET(user?.id, joinedStudySetId)}
            onClick={onClose}
          >
            {t('common.go')}
          </Button>
        ) : (
          <LoadingButton loading={loading} variant="contained" onClick={handleSubmit}>
            {t('common.join')}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
}
