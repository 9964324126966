import './index.css';

export function OrbitSpinner() {
  return (
    <div className="orbit-spinner">
      <div className="orbit" />
      <div className="orbit" />
      <div className="orbit" />
    </div>
  );
}
