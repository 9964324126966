import { createTheme, alpha } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4AFFD7',
      light: alpha('#4AFFD7', 0.5),
      dark: alpha('#4AFFD7', 0.8),
    },
    secondary: {
      main: '#363563',
      light: alpha('#363563', 0.5),
      dark: alpha('#363563', 0.8),
    },
    background: {
      default: '#1B1930',
      paper: '#24243E',
    },
    text: {
      primary: '#EEEEEE',
      secondary: '#8281a0',
    },
    error: {
      main: '#f44336',
      light: '#ff1100',
      dark: '#f91d0d',
    },
    success: {
      main: '#4AFFD7',
    },
    warning: {
      main: '#FFEB3B',
    },
  },
  typography: {
    h1: {
      fontSize: '2rem',
    },
    h2: {
      fontSize: '1.5rem',
    },
    h4: {
      fontSize: '1rem',
      lineHeight: 1.2,
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.5,
    },
    button: {
      textTransform: 'none',
      fontSize: '1rem',
      fontWeight: 500,
    },
  },
  shape: {
    borderRadius: 8,
  },
  spacing: 8,

  components: {
    MuiInputBase: {
      defaultProps: {
        disableInjectingGlobalStyles: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderRadius: '20px',
          '&.Mui-disabled': {
            backgroundColor: '#373665',
            color: '#5c5c88',
          },
        },
        contained: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
          '&:active': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #363563',
          boxShadow: '0px 9px 14px #171428',
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderRadius: '20px',
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: '#363563',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: '#1B1930',
          borderRadius: 4,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: '#363563',
          borderRadius: '8px',
          '& .MuiOutlinedInput-root': {
            '& fieldset, &:hover fieldset': {
              borderColor: '#363563',
            },
            '&:hover fieldset': {
              borderColor: '#4AFFD7',
            },
            '&:active fieldset': {
              borderColor: '#4AFFD7',
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor: '#363563',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:last-child td': {
            border: 0,
          },
          '&.Mui-selected': {
            backgroundColor: '#1B1930',
            '&:hover': {
              backgroundColor: alpha('#1B1930', 0.8),
            },
          },
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        valueLabel: {
          backgroundColor: '#363563',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#1B1930',
          boxShadow: '0px 1px 1px 0px #363563',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          '&:hover': {
            color: '#4AFFD7',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          color: '#363563',
        },
        tooltip: {
          backgroundColor: '#363563',
          maxWidth: 180,
          fontSize: 14,
          textAlign: 'center',
          margin: 0,
          boxShadow: '0px 1px 6px #171428',
        },
        popper: {
          '&[data-popper-placement*="top"] .MuiTooltip-tooltip': {
            marginBottom: '16px !important',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          backgroundColor: '#363563',
          '& .MuiSelect-select': {
            backgroundColor: '#363563',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#4AFFD7',
          },
          '& .MuiSvgIcon-root': { color: '#EEEEEE' },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: '#4AFFD7',
            color: '#000',
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          '&:not(.Mui-checked)': {
            '& + .MuiSwitch-track': {
              backgroundColor: '#363563',
              opacity: 1,
            },
          },
          '&.Mui-disabled': {
            color: '#bfbfbf',
            '& + .MuiSwitch-track': {
              backgroundColor: '#bfbfbf',
              opacity: 0.6,
            },
          },
        },
        thumb: {
          '&.Mui-disabled': {
            color: '#bfbfbf',
            opacity: 0.8,
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          color: '#8281a0',
          '& .MuiFormControlLabel-label.Mui-disabled': {
            color: '#9e9e9e',
          },
        },
      },
    },
  },
});

export default theme;
