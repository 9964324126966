import { Avatar, List, ListItem, Paper, ListItemText, Typography, Skeleton } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';
import { useUserStore, getInitials } from 'stores/user';

export function BaseInfo() {
  const { user, statistics } = useUserStore();
  const { t } = useTranslation();

  return (
    <>
      <Grid xs={12} sm={4} md={3}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: { xs: 'row', sm: 'column' },
            alignItems: 'center',
          }}
        >
          {user ? (
            <>
              <Avatar
                sx={{
                  fontSize: 24,
                  bgcolor: 'primary.main',
                  width: 60,
                  height: 60,
                }}
              >
                {getInitials(user)}
              </Avatar>
              <Typography
                variant="h2"
                sx={{
                  mt: { xs: 0, sm: 1 },
                  ml: { xs: 2, sm: 0 },
                  fontSize: '1.25rem',
                  textAlign: 'center',
                }}
              >
                {user?.firstName} {user?.lastName}
              </Typography>
            </>
          ) : (
            <>
              <Skeleton variant="circular" width={80} height={80} />
              <Skeleton sx={{ py: 1, mx: 1, width: { xs: '50%', sm: '100%' } }} />
            </>
          )}
        </Paper>
      </Grid>
      <Grid xs={12} sm={8} md={9}>
        <Paper sx={{ p: 2 }}>
          {statistics ? (
            <>
              <Typography variant="h2" color="primary" align="center">
                {t('profile.header')}
              </Typography>
              <List sx={{ py: 0 }}>
                <ListItem sx={{ p: 0 }}>
                  <ListItemText
                    sx={{ flex: 'none', minWidth: 120, pr: 2 }}
                    secondary={t('profile.total_points_scored')}
                  />
                  <ListItemText primary={statistics.points} />
                </ListItem>
                <ListItem sx={{ p: 0 }}>
                  <ListItemText
                    sx={{ flex: 'none', minWidth: 120, pr: 2 }}
                    secondary={t('profile.terms_learned')}
                  />
                  <ListItemText primary={statistics.numLearnedCards} />
                </ListItem>
              </List>
            </>
          ) : (
            <>
              <Skeleton sx={{ py: 1 }} />
              <Skeleton sx={{ py: 1 }} />
              <Skeleton sx={{ py: 1 }} />
            </>
          )}
        </Paper>
      </Grid>
    </>
  );
}
