import { Typography, Box } from '@mui/material';
import { ReactNode } from 'react';
import { OrbitSpinner } from '../OrbitSpinner/OrbitSpinner';

interface ListWrapperProps<T> {
  children: ReactNode;
  list: T[] | null;
  emptyListMsg: string | ReactNode;
  noMatchMsg?: string | ReactNode;
  isFiltered?: boolean;
}

interface StubRendererProps {
  item: string | ReactNode;
}

function StubRenderer({ item }: StubRendererProps) {
  if (typeof item === 'string') {
    return (
      <Typography variant="body1" align="center" my={2}>
        {item}
      </Typography>
    );
  }
  return <>{item ?? null}</>;
}

export function ListWrapper<T>({
  children,
  list,
  emptyListMsg,
  isFiltered,
  noMatchMsg,
}: ListWrapperProps<T>) {
  return (
    <>
      {list ? (
        list.length ? (
          children
        ) : (
          <StubRenderer item={isFiltered ? noMatchMsg : emptyListMsg} />
        )
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
          <OrbitSpinner />
        </Box>
      )}
    </>
  );
}
