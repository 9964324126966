import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { routes } from 'routes';
import { useAuthStore } from 'stores/auth';
import { OrbitSpinner } from 'components/commons/OrbitSpinner/OrbitSpinner';
import { BASE_BG_COLOR } from 'constants/index';

export default function App() {
  const [isAppInitialized, setIsAppInitialized] = useState(false);
  const { authorize, getAuthUser } = useAuthStore();

  useEffect(() => {
    const { initData, setHeaderColor, expand, disableVerticalSwipes } = window.Telegram.WebApp;
    disableVerticalSwipes();
    setHeaderColor(BASE_BG_COLOR);
    expand();
    (async () => {
      await authorize(initData);
      await getAuthUser();
      setIsAppInitialized(true);
    })();
  }, []);

  return isAppInitialized ? (
    <RouterProvider router={routes} />
  ) : (
    <Grid display="flex" justifyContent="center" alignItems="center" sx={{ height: '100vh' }}>
      <OrbitSpinner />
    </Grid>
  );
}
