import { Stack } from '@mui/material';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { StudySetRouteParams } from 'routes/routes.types';
import { useStudySetStore } from 'stores/studySet';
import { useUserStore } from 'stores/user';
import { DetailsBlock } from './components/DetailsBlock/DetailsBlock';
import { TermsBlock } from './components/TermsBlock/TermsBlock';

export function StudySet() {
  const { setId, userId } = useParams() as StudySetRouteParams;
  const { fetch, reset } = useStudySetStore();
  const { fetchUserSets } = useUserStore();

  useEffect(() => {
    (async () => {
      await fetch(setId);
      await fetchUserSets(userId);
    })();
    return reset;
  }, [setId]);

  return (
    <Stack spacing={2} sx={{ mt: { xs: 1, sm: 2 } }}>
      <DetailsBlock />
      <TermsBlock />
    </Stack>
  );
}
