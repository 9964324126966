/* eslint-disable react/display-name */
import { Box, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { useState, forwardRef, useEffect, useRef } from 'react';
import { TableVirtuoso, TableComponents } from 'react-virtuoso';
import { StudySetCard, useStudySetStore } from 'stores/studySet';
import { TermsTableHead } from './TermsTableHead';
import { TermsTableRow } from './TermsTableRow';
import { DeleteCardsDialog } from '../DeleteCardsDialog';
import { useSortableData } from '../TermsBlock.hooks';
import { useDynamicHeight } from '../../../StudySet.hook';

interface TermsTableProps {
  search: string;
  cards: StudySetCard[];
  selected: Set<string>;
  isOwner: boolean;
  isParticipant: boolean;
  onSelectAll: (checked: boolean) => void;
  onSelect: (id: string) => void;
}

export function TermsTable({
  search,
  cards,
  selected,
  isOwner,
  isParticipant,
  onSelectAll,
  onSelect,
}: TermsTableProps) {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deletedId, setDeletedId] = useState<string[]>([]);
  const { items, sortConfig, requestSort } = useSortableData(cards);
  const { isDetailsExpanded } = useStudySetStore();
  const { containerRef, height } = useDynamicHeight(isDetailsExpanded);
  const scrollPosition = useRef({ top: 0, left: 0 });

  const handleDelete = (id: string) => {
    setDeletedId([id]);
    setShowDeleteDialog(true);
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = scrollPosition.current.top;
      containerRef.current.scrollLeft = scrollPosition.current.left;
    }
  }, [items]);

  const VirtuosoTableComponents: TableComponents<StudySetCard> = {
    Scroller: forwardRef<HTMLDivElement>((props, ref) => (
      <TableContainer
        sx={{ overscrollBehavior: 'none', borderRadius: 1 }}
        ref={(el) => {
          if (ref) {
            if (typeof ref === 'function') ref(el);
            else ref.current = el;
          }
          if (el) {
            el.scrollTop = scrollPosition.current.top;
            el.scrollLeft = scrollPosition.current.left;
          }
        }}
        onScroll={(e) => {
          scrollPosition.current.top = e.currentTarget.scrollTop;
          scrollPosition.current.left = e.currentTarget.scrollLeft;
        }}
        {...props}
      />
    )),
    Table: (props) => <Table {...props} sx={{ backgroundColor: 'background.paper' }} />,
    TableHead: forwardRef((props, ref) => <TableHead {...props} ref={ref} />),
    TableRow: ({ item: card, ...props }) => (
      <TableRow
        hover
        role="checkbox"
        selected={selected.has(card.id)}
        onClick={() => onSelect(card.id)}
        {...props}
      />
    ),
    TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
  };

  return (
    <Box ref={containerRef} sx={{ height }}>
      <TableVirtuoso
        data={items}
        components={VirtuosoTableComponents}
        fixedHeaderContent={() => (
          <TermsTableHead
            search={search}
            cards={cards}
            selected={selected}
            isOwner={isOwner}
            isParticipant={isParticipant}
            sortConfig={sortConfig}
            requestSort={requestSort}
            onSelectAll={onSelectAll}
          />
        )}
        itemContent={(_index: number, card: StudySetCard) => (
          <TermsTableRow
            key={card.id}
            card={card}
            isOwner={isOwner}
            isParticipant={isParticipant}
            selected={selected}
            onDelete={handleDelete}
          />
        )}
      />
      <DeleteCardsDialog
        ids={deletedId}
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
      />
    </Box>
  );
}
