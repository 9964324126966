import { create } from 'zustand';
import {
  getUserInfo,
  getUserSets,
  getIntervalTrainingSettings,
  updateIntervalTrainingSettings,
  updateUserSettings,
} from 'graphql/user';
import { isDev } from 'utils';
import i18n from 'services/i18n';
import { NotificationTypes } from 'stores/app/app.types';
import {
  User,
  UserStatistics,
  Trophy,
  UserSetCard,
  UserSettings,
  SettingItemValue,
} from './user.types';
import {
  getUserMock,
  getUserStatsMock,
  trophyListMock,
  userSetsMock,
  userSettingsMock,
} from './user.mocks';

interface UserState {
  user: User | null;
  statistics: UserStatistics | null;
  trophies: Trophy[] | null;
  sets: UserSetCard[] | null;
  settings: UserSettings | null;
}

interface UserActions {
  fetchUserInfo: (userId: string) => Promise<void>;
  fetchUserSets: (userId: string) => Promise<void>;
  fetchUserSettings: () => Promise<void>;
  updateSettings: (value: SettingItemValue, type: string) => Promise<NotificationTypes>;
  reset: () => void;
}

const initialState: UserState = {
  user: null,
  statistics: null,
  trophies: null,
  sets: null,
  settings: null,
};

export const useUserStore = create<UserState & UserActions>((set) => ({
  ...initialState,
  fetchUserInfo: async (userId: string) => {
    try {
      const res = await getUserInfo(userId);
      const { user, userStats, userTrophies } = res.profile;
      set({ user, statistics: userStats, trophies: userTrophies });
    } catch (error) {
      if (isDev()) {
        set({
          user: getUserMock(),
          statistics: getUserStatsMock(),
          trophies: trophyListMock,
        });
        return;
      }
      console.error('getUserInfo error:', error);
    }
  },
  fetchUserSets: async (userId: string) => {
    try {
      const res = await getUserSets(userId);
      const { sets } = res.userSets;
      set({ sets });
    } catch (error) {
      if (isDev()) {
        set({ sets: userSetsMock });
        return;
      }
      console.error('getUserSets error:', error);
    }
  },
  fetchUserSettings: async () => {
    try {
      const { intervalTrainingSettings } = await getIntervalTrainingSettings();
      const { language } = i18n;
      const settings = { ...intervalTrainingSettings, language } as UserSettings;
      set({ settings });
    } catch (error) {
      if (isDev()) {
        set({ settings: userSettingsMock });
        return;
      }
      console.error('getIntervalTrainingSettings error:', error);
    }
  },
  updateSettings: async (value, type) => {
    set((state) => {
      if (!state.settings) return state;
      return { settings: { ...state.settings, [type]: value } };
    });
    try {
      if (type === 'language') {
        i18n.changeLanguage(value as string);
        await updateUserSettings({ language: value as string });
      } else {
        await updateIntervalTrainingSettings({ [type]: value });
      }
      return 'success';
    } catch (error) {
      console.error('updateSettingsRequest error:', error);
      return 'error';
    }
  },
  reset: () => set(initialState),
}));
