import ReactCardFlip from 'react-card-flip';
import { useState } from 'react';
import { useCardStore } from 'stores/card';
import { ToolbarFront } from './ToolbarFront';
import { ToolbarBack } from './ToolbarBack';
import { DeleteCardsDialog } from '../DeleteCardsDialog';
import { UpdateLearningLevelDialog } from '../UpdateLearningLevelDialog';

interface TermsToolbarProps {
  selected: Set<string>;
  search: string;
  isOwner: boolean;
  onSearchChange: (val: string) => void;
}

export function TermsToolbar({ selected, search, isOwner, onSearchChange }: TermsToolbarProps) {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showLearningLevelDialog, setShowLearningLevelDialog] = useState(false);
  const { openCardManager } = useCardStore();

  return (
    <>
      <ReactCardFlip isFlipped={Boolean(selected.size)} flipDirection="vertical">
        <ToolbarFront
          isOwner={isOwner}
          selected={selected}
          search={search}
          onSearch={onSearchChange}
          onCreate={openCardManager}
        />
        <ToolbarBack
          selected={selected}
          isOwner={isOwner}
          onChangeLearningLevel={() => setShowLearningLevelDialog(true)}
          onEdit={() => openCardManager(Array.from(selected))}
          onDelete={() => setShowDeleteDialog(true)}
        />
      </ReactCardFlip>
      <DeleteCardsDialog
        ids={Array.from(selected)}
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
      />
      <UpdateLearningLevelDialog
        ids={Array.from(selected)}
        open={showLearningLevelDialog}
        onClose={() => setShowLearningLevelDialog(false)}
      />
    </>
  );
}
