import { useState, useMemo } from 'react';
import { UserSetCard } from 'stores/user';

export function useUserSets(sets: UserSetCard[]) {
  const [filter, setFilter] = useState('');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | 'none'>('none');

  const handleSortOrderChange = () => {
    setSortOrder((prevSortOrder) => {
      switch (prevSortOrder) {
        case 'asc':
          return 'desc';
        case 'desc':
          return 'none';
        case 'none':
        default:
          return 'asc';
      }
    });
  };

  const filteredAndSortedSets = useMemo(() => {
    if (!sets) return null;
    let result = sets.filter((set) => set.name.toLowerCase().includes(filter.toLowerCase()));

    if (sortOrder !== 'none') {
      result = result.sort((a, b) =>
        sortOrder === 'asc' ? a.progress - b.progress : b.progress - a.progress
      );
    }

    return result;
  }, [sets, filter, sortOrder]);

  return {
    filter,
    sortOrder,
    setFilter,
    handleSortOrderChange,
    filteredAndSortedSets,
  };
}
