import { client } from 'services/graphql';
import { UpateStudySetData } from 'stores/studySet';
import {
  createStudySetMutation,
  activateStudySetMutation,
  deleteStudySetMutation,
  updateStudySetMutation,
  removeStudySetMutation,
  addStudySetMutation,
} from './mutations';
import { getStudySetQuery } from './queries';

export const createStudySet = async (name: string, visibility: string) =>
  await client.request(createStudySetMutation, { name, visibility });

export const activateStudySet = async (userSetId: string) =>
  await client.request(activateStudySetMutation, { userSetId });

/* delete set as owner */
export const deleteStudySet = async (userSetId: string) =>
  await client.request(deleteStudySetMutation, { userSetId });

/* remove set as participant */
export const removeStudySet = async (userSetId: string) =>
  await client.request(removeStudySetMutation, { userSetId });

export const addStudySet = async (userSetId: string) =>
  await client.request(addStudySetMutation, { userSetId });

export const updateStudySet = async ({
  userSetId,
  title,
  description,
  visibility,
  level,
}: UpateStudySetData) =>
  await client.request(updateStudySetMutation, {
    userSetId,
    title,
    description,
    visibility,
    level,
  });

export const getStudySet = async (userSetId: string) =>
  await client.request(getStudySetQuery, { userSetId });
