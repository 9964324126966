import { Paper, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthStore } from 'stores/auth';
import { useUserStore, UserSetCard } from 'stores/user';
import { ListWrapper } from 'components/commons';
import { JoinStudySetDialog } from 'components/JoinStudySetDialog/JoinStudySetDialog';
import { FilterSortControls } from './Partials/FilterSortControls';
import { SetItem } from './Partials/SetItem';
import { AddSetCard } from './Partials/AddSetCard';
import { useUserSets } from './UserSets.hooks';

export function UserSets() {
  const { sets, user, fetchUserSets } = useUserStore();
  const { t } = useTranslation();
  const { user: authorizeUser } = useAuthStore();
  const [showJoinDialog, setShowJoinDialog] = useState(false);
  const [studySetIdToJoin, setStudySetIdToJoin] = useState('');
  const { filter, sortOrder, setFilter, handleSortOrderChange, filteredAndSortedSets } =
    useUserSets(sets as UserSetCard[]);

  const isOwner = user?.id === authorizeUser?.id;

  const handleJoin = (setId: string) => {
    setStudySetIdToJoin(setId);
    setShowJoinDialog(true);
  };

  const emptyListBlock = isOwner ? (
    <Grid xs={12} sm={6} md={4}>
      <AddSetCard />
    </Grid>
  ) : (
    t('profile_sets.no_sets_msg')
  );

  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h2" color="primary" align="center">
        {t('profile_sets.title')}
      </Typography>
      {!!sets?.length && (
        <FilterSortControls
          filter={filter}
          sortOrder={sortOrder}
          isOwner={isOwner}
          setFilter={setFilter}
          onSortOrderChange={handleSortOrderChange}
        />
      )}
      <ListWrapper
        list={filteredAndSortedSets}
        isFiltered={!!filter}
        emptyListMsg={emptyListBlock}
        noMatchMsg={
          <Typography variant="body1" align="center" my={4} pb="200px">
            {t('profile_sets.no_matches')}
          </Typography>
        }
      >
        <Grid container spacing={2} sx={{ mt: 2, pb: { xs: filter ? '300px' : 0, sm: 0 } }}>
          {isOwner && (
            <Grid xs={12} sm={6} md={4}>
              <AddSetCard />
            </Grid>
          )}
          {filteredAndSortedSets?.map((set) => (
            <Grid key={set.id} xs={12} sm={6} md={4}>
              <SetItem set={set} isOwner={isOwner} onJoin={handleJoin} />
            </Grid>
          ))}
        </Grid>
        <JoinStudySetDialog
          setId={studySetIdToJoin}
          open={showJoinDialog}
          onClose={() => setShowJoinDialog(false)}
          onJoined={() => user && fetchUserSets(user.id)}
        />
      </ListWrapper>
    </Paper>
  );
}
