import { graphql } from 'services/graphql';

export const authTelegramWebAppMutation = graphql`
  mutation telegramWebAuth($initData: String!) {
    telegramWebAuth(initData: $initData) {
      user {
        id
        firstName
        lastName
        username
      }
    }
  }
`;
