import { create } from 'zustand';
import { getRatings } from 'graphql/ratings';
import { isDev } from 'utils';
import { UserRating, RatingItem } from './ratings.types';
import { allStatsMock, getCurrentUserStatsMock } from './raitings.mocks';

interface RatingsState {
  generalRatings: RatingItem[] | null;
  userRatings: UserRating | null;
}

interface RatingsActions {
  fetch: (period: string) => Promise<void>;
  reset: () => void;
}

const initialState: RatingsState = {
  generalRatings: null,
  userRatings: null,
};

export const useRatingsStore = create<RatingsState & RatingsActions>((set) => ({
  ...initialState,
  fetch: async (period: string) => {
    try {
      const res = await getRatings(period);
      const { items, currentUserRating } = res.userRatings;
      set({ userRatings: currentUserRating, generalRatings: items });
    } catch (error) {
      if (isDev()) {
        set({ userRatings: getCurrentUserStatsMock(), generalRatings: allStatsMock });
        return;
      }
      console.error('getRatings error:', error);
    }
  },
  reset: () => set(initialState),
}));
