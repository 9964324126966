// @ts-expect-error This is a known issue with type inference
import { GraphQLClient, gql } from 'graphql-request';
import { isDev } from 'utils';

export const client = new GraphQLClient(`https://${process.env.REACT_APP_WEB_APP_DOMAIN}/graphql`, {
  credentials: isDev() ? 'include' : 'same-origin',
  mode: 'cors',
});

export const graphql = gql;
