import { useCardStore } from './card.store';
import { isCardChanged } from './card.utils';

export const useNotSavedCardIds = (): string[] => {
  const { cards, cardsToSubmit } = useCardStore();
  const changedCardIds = cards
    .filter((card) => {
      const cardToSubmit = cardsToSubmit.find(({ id }) => id === card.id);
      const areRequiredFieldsFilled =
        cardToSubmit?.terms.length && cardToSubmit?.relatedTerms.length;
      return cardToSubmit && areRequiredFieldsFilled && isCardChanged(cardToSubmit, card);
    })
    .map((card) => card.id);
  return changedCardIds;
};
