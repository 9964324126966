import { create } from 'zustand';
import { deleteCards, updateCardsLearningLevel, createCards, updateCards } from 'graphql/card';
import { useAppStore } from 'stores/app';
import { useStudySetStore } from 'stores/studySet';
import { CardTemplate, Mode } from './card.types';
import { generateCardTemplate, getReadyToSubmitCards } from './card.utils';

interface CardState {
  mode: Mode;
  showCardManager: boolean;
  cardsToSubmit: CardTemplate[];
  cards: CardTemplate[];
}

interface CardActions {
  openCardManager: (editingIds?: string[]) => void;
  closeCardManager: () => void;
  addCard: () => void;
  updateCardToSubmitById: (cardId: string, updatedCard: CardTemplate) => void;
  removeCardById: (cardId: string) => void;
  create: (setId: string) => Promise<void>;
  update: (setId: string) => Promise<void>;
  updateLearningLevel: (studySetId: string, cardIds: string[], level: number) => Promise<void>;
  delete: (studySetId: string, cardIds: string[]) => Promise<void>;
  reset: () => void;
}

const initialState: CardState = {
  mode: 'creation',
  showCardManager: false,
  cardsToSubmit: [],
  cards: [],
};

export const useCardStore = create<CardState & CardActions>((set, get) => ({
  ...initialState,
  openCardManager: (editingIds = []) => {
    if (editingIds.length) {
      const { studySet } = useStudySetStore.getState();
      const cards = studySet?.cards
        .filter(({ id }) => editingIds.includes(id))
        .map(generateCardTemplate);
      set({ cards, cardsToSubmit: cards, mode: 'editing', showCardManager: true });
      return;
    }
    get().addCard();
    set({ mode: 'creation', showCardManager: true });
  },
  closeCardManager: () => {
    set(initialState);
  },
  addCard: () => {
    const card = generateCardTemplate();
    set((state) => ({
      cards: [card, ...state.cards],
      cardsToSubmit: [card, ...state.cardsToSubmit],
    }));
  },
  updateCardToSubmitById: (id, updatedCard) => {
    set((state) => ({
      cardsToSubmit: state.cardsToSubmit.map((card) =>
        card.id === id ? { ...card, ...updatedCard } : card
      ),
    }));
  },
  removeCardById: (id) => {
    set((state) => {
      const updatedCards = state.cards.filter((card) => card.id !== id);
      const updatedCardsToSubmit = state.cardsToSubmit.filter((card) => card.id !== id);
      return {
        cards: updatedCards,
        cardsToSubmit: updatedCardsToSubmit,
      };
    });
  },
  create: async (setId) => {
    const { cardsToSubmit } = get();
    const [filledCards, restCards] = getReadyToSubmitCards(cardsToSubmit);
    // eslint-disable-next-line
    const cardsWithoutId = filledCards.map(({ id, ...rest }) => rest);
    try {
      const { createUserCards } = await createCards(setId, cardsWithoutId);
      if (createUserCards?.errors?.length) {
        const [error] = createUserCards.errors;
        useAppStore.getState().setServiceMessageCode(error.code);
        return;
      }
      set(() => ({ cards: restCards, cardsToSubmit: restCards }));
      useAppStore.getState().setNotificationBar('success');
      useStudySetStore.getState().refresh();
    } catch (error) {
      console.error('createCards error:', error);
      useAppStore.getState().setNotificationBar('error');
    }
  },
  update: async (setId) => {
    const { cardsToSubmit } = get();
    const [filledCards, restCards] = getReadyToSubmitCards(cardsToSubmit);
    try {
      await updateCards(setId, filledCards);
      set(() => ({ cards: restCards, cardsToSubmit: restCards }));
      useAppStore.getState().setNotificationBar('success');
      useStudySetStore.getState().refresh();
    } catch (error) {
      console.error('updateCards error:', error);
      useAppStore.getState().setNotificationBar('error');
    }
  },
  updateLearningLevel: async (studySetId, cardIds, level) => {
    try {
      const { setCardsLearningLevel } = await updateCardsLearningLevel(studySetId, cardIds, level);
      if (setCardsLearningLevel.isSuccess) {
        useAppStore.getState().setNotificationBar('success');
        useStudySetStore.getState().refresh();
      }
    } catch (error) {
      console.error('updateLearningLevel error:', error);
      useAppStore.getState().setNotificationBar('error');
    }
  },
  delete: async (studySetId, cardIds) => {
    try {
      const { deleteUserCards } = await deleteCards(studySetId, cardIds);
      if (deleteUserCards.isSuccess) {
        useAppStore.getState().setNotificationBar('success');
        useStudySetStore.getState().refresh();
      }
    } catch (error) {
      console.error('delete error:', error);
      useAppStore.getState().setNotificationBar('error');
    }
  },
  reset: () => set(initialState),
}));
