import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
  Switch,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';
import { useState, ChangeEvent } from 'react';
import { useParams } from 'react-router-dom';
import { useStudySetStore, Visibility } from 'stores/studySet';
import { StudySetRouteParams } from 'routes/routes.types';
import { STUDY_SET_NAME_LENGTH_LIMIT } from 'constants/index';

interface UpdateStudySetDialogProps {
  open: boolean;
  onClose: () => void;
}

export function UpdateStudySetDialog({ open, onClose }: UpdateStudySetDialogProps) {
  const { studySet, update: updateStudySet } = useStudySetStore();
  const [fields, setFields] = useState({
    name: studySet?.name,
    description: studySet?.description,
    isPublic: studySet?.isPublic,
  });
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { setId } = useParams() as StudySetRouteParams;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFields({
      ...fields,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    const isUpdated = await updateStudySet({
      userSetId: setId,
      title: fields.name?.trim(),
      description: fields.description?.trim() ?? undefined,
      visibility: fields.isPublic ? Visibility.public : Visibility.private,
      level: 'mixed',
    });
    setLoading(false);
    if (isUpdated) onClose();
  };

  return (
    <Dialog fullWidth sx={{ top: { xs: '-40vh', sm: 0 } }} open={open} onClose={onClose}>
      <DialogTitle>{t('dialog_edit_set.title')}</DialogTitle>
      <DialogContent sx={{ pb: 1 }}>
        <TextField
          autoFocus
          variant="outlined"
          margin="dense"
          label={t('dialog_edit_set.title_name')}
          autoComplete="off"
          name="name"
          fullWidth
          sx={{ mb: 2 }}
          inputProps={{ maxLength: STUDY_SET_NAME_LENGTH_LIMIT }}
          value={fields.name}
          onChange={handleChange}
        />
        <TextField
          variant="outlined"
          margin="dense"
          label={t('dialog_edit_set.title_description')}
          autoComplete="off"
          name="description"
          fullWidth
          sx={{ mb: 2 }}
          value={fields.description}
          onChange={handleChange}
        />
        <Typography variant="body1">{t('dialog_edit_set.title_public')}</Typography>
        <Switch name="isPublic" checked={fields.isPublic} onChange={handleChange} />
        <Typography display="inline" color="text.secondary">
          {fields.isPublic
            ? t('dialog_edit_set.title_public_yes')
            : t('dialog_edit_set.title_public_no')}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ p: 3, pt: 0 }}>
        <Button variant="contained" color="secondary" onClick={onClose}>
          {t('common.close')}
        </Button>
        <LoadingButton
          disabled={!fields.name?.trim()}
          loading={loading}
          variant="contained"
          onClick={handleSubmit}
        >
          {t('common.save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
