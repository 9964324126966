import { nanoid } from 'nanoid';
import { StudySetCard } from 'stores/studySet';
import { CardTemplate } from './card.types';

export const generateCardTemplate = (card?: StudySetCard): CardTemplate => {
  if (card) {
    const { id, terms, relatedTerms, definitions, examples, learningLevel } = card;
    return {
      id,
      terms,
      relatedTerms,
      definitions,
      examples,
      learningLevel,
    };
  }
  return {
    id: nanoid(),
    terms: [],
    relatedTerms: [],
    definitions: [],
    examples: [],
    learningLevel: 0,
  };
};

export const isCardChanged = (initialCard: CardTemplate, card: CardTemplate) => {
  const mutableKeys: (keyof CardTemplate)[] = [
    'terms',
    'relatedTerms',
    'definitions',
    'examples',
    'learningLevel',
  ];
  return mutableKeys.some((key) => JSON.stringify(initialCard[key]) !== JSON.stringify(card[key]));
};

export function getReadyToSubmitCards(cards: CardTemplate[]): [CardTemplate[], CardTemplate[]] {
  const filledCards: CardTemplate[] = [];
  const restCards: CardTemplate[] = [];

  cards.forEach((card) => {
    if (card.terms.length > 0 && card.relatedTerms.length > 0) {
      filledCards.push(card);
    } else {
      restCards.push(card);
    }
  });

  return [filledCards, restCards];
}
