import { Stack, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { SettingItemValue } from 'stores/user';

interface SelectionOption {
  value: number | string;
  label: string;
}

interface SettingItemProps {
  name: string;
  inputLabel: string;
  value: SettingItemValue | null;
  type: string;
  options: SelectionOption[];
  onChange: (value: SettingItemValue, type: string) => void;
}

export function SettingItem({
  name,
  inputLabel,
  value,
  type,
  options,
  onChange,
}: SettingItemProps) {
  const { t } = useTranslation();
  return (
    <>
      <Stack width={'100%'} maxWidth={600}>
        <FormControl fullWidth>
          <InputLabel id={`${name}-select-label`}>{t(inputLabel)}</InputLabel>
          <Select
            IconComponent={(props) => <ExpandMore {...props} sx={{ fontSize: 30 }} />}
            labelId={`${name}-select-label`}
            label={t(inputLabel)}
            id={`${name}-select`}
            value={value ?? ''}
            onChange={(event) => onChange(event.target.value, type)}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {t(option.label)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    </>
  );
}
