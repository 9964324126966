// import { nanoid } from 'nanoid';
import { User, UserStatistics, Trophy } from './user.types';

export const getUserMock = (overrides?: Partial<User>): User => ({
  id: 'arnie-dev-test-id',
  firstName: 'Arnie',
  lastName: 'English',
  ...overrides,
});

export const getUserStatsMock = (overrides?: Partial<UserStatistics>): UserStatistics => ({
  points: 5000,
  levelVocabulary: 'Upper Intermediate',
  numLearnedCards: 10,
  ...overrides,
});

export const getUserTrophyMockGold = (overrides?: Partial<Trophy>): Trophy => ({
  number: 1,
  info: {
    version: 1,
    type: 'gold',
    title: 'Gold Name',
    name: 'Golden Day',
    url: 'https://i.imgur.com/vlP2fl9.png',
    rarity: 0.3,
  },
  ...overrides,
});

export const getUserTrophyMockSilver = (overrides?: Partial<Trophy>): Trophy => ({
  number: 2,
  info: {
    version: 1,
    type: 'silver',
    title: 'Silver Name',
    name: 'Silver Day',
    url: 'https://i.imgur.com/jPz0Zbq.png',
    rarity: 0.3,
  },
  ...overrides,
});

export const getUserTrophyMockBronze = (overrides?: Partial<Trophy>): Trophy => ({
  number: 2,
  info: {
    version: 1,
    type: 'bronze',
    title: 'Bronze Name',
    name: 'Bronze Twilight',
    url: 'https://i.imgur.com/ZvsvRBe.png',
    rarity: 0.2,
  },
  ...overrides,
});

const trophyMock = getUserTrophyMockGold();

export const trophyListMock = [
  trophyMock,
  getUserTrophyMockGold({
    info: { ...trophyMock.info, version: 3 },
  }),
  getUserTrophyMockGold({
    info: { ...trophyMock.info, version: 1 },
  }),
  getUserTrophyMockGold({
    info: { ...trophyMock.info, version: 4 },
  }),
  getUserTrophyMockSilver(),
  getUserTrophyMockBronze(),
];

export const userSetsMock = [
  {
    id: 'dc9e2479-ab10-438b-8d7d-c4e843cc7502',
    name: '📓 My study set',
    progress: 78.08,
    level: 'mixed',
    totalCards: 52,
    numLearnedCards: 30,
    isActive: true,
    owner: {
      id: '1bdb17a7-b078-45bd-a89f-aaaf24dad42c',
      firstName: 'Dmytro',
      lastName: 'K',
    },
    requestUserMeta: {
      isJoinable: false,
      isParticipant: false,
    },
  },
  {
    id: '86305587-c16b-4a54-beb3-dce2a96c32fc',
    name: 'Gerunds and infinit2',
    progress: 0,
    level: 'mixed',
    totalCards: 0,
    numLearnedCards: 0,
    isActive: false,
    owner: {
      id: '1bdb17a7-b078-45bd-a89f-aaaf24dad42c',
      firstName: 'Dmytro',
      lastName: 'K',
    },
    requestUserMeta: {
      isJoinable: false,
      isParticipant: false,
    },
  },
];

export const userSettingsMock = {
  trainingEnabled: true,
  interval: null,
  startTime: '13:00',
  endTime: '20:00',
  timezoneOffset: -12,
  language: 'en',
};
