import {
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { NavItem } from '../types';

export interface PropsBottomNavbar {
  navItems: NavItem[];
}
export function BottomNavbar({ navItems }: PropsBottomNavbar) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [currentChildren, setCurrentChildren] = useState<NavItem[]>([]);
  const location = useLocation();

  const getColor = (path?: string) => {
    return location.pathname === path ? 'primary.main' : 'text.primary';
  };

  const showChildren = (childrens: NavItem[]) => {
    setCurrentChildren(childrens);
    setIsDrawerOpen(true);
  };

  return (
    <AppBar position="fixed" sx={{ top: 'auto', bottom: 0 }}>
      <BottomNavigation showLabels>
        {navItems.map((item) => (
          <BottomNavigationAction
            key={item.label}
            sx={{ color: getColor(item.path) }}
            label={item.label}
            icon={item.icon}
            component={item.path ? Link : 'div'}
            to={item.path as string}
            onClick={() => {
              !!item.childrens && showChildren(item.childrens);
              item.handler?.();
            }}
          />
        ))}
      </BottomNavigation>
      <Drawer anchor="bottom" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <List>
          {currentChildren.map((item) => (
            <ListItem key={item.label} disablePadding>
              <ListItemButton
                onClick={() => {
                  item.handler?.();
                  setIsDrawerOpen(false);
                }}
              >
                {item.icon}
                <ListItemText primary={item.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </AppBar>
  );
}
