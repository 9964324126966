import { Paper, TextField, IconButton, Fab } from '@mui/material';
import { Search, Add, Clear } from '@mui/icons-material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCardsLimitation } from 'stores/studySet';
import { DangerToolTip } from 'components/commons';

interface ToolbarFrontProps {
  selected: Set<string>;
  search: string;
  isOwner: boolean;
  onSearch: (val: string) => void;
  onCreate: () => void;
}

export function ToolbarFront({ search, isOwner, onSearch, onCreate }: ToolbarFrontProps) {
  const [showTooltipLimitation, setShowTooltipLimitation] = useState(false);
  const isTermsLimitNotExceeded = useCardsLimitation();
  const { t } = useTranslation();

  const handleCreateClick = () => {
    isTermsLimitNotExceeded() ? onCreate() : setShowTooltipLimitation(true);
  };

  return (
    <Paper key="front" sx={{ px: 2, py: 1, display: 'flex', minHeight: 58, alignItems: 'center' }}>
      <TextField
        variant="outlined"
        size="small"
        autoComplete="off"
        placeholder={t('common.search')}
        value={search}
        sx={{ flexGrow: 1 }}
        InputProps={{
          startAdornment: <Search sx={{ mr: 1 }} />,
          endAdornment: search && (
            <IconButton edge="end" onClick={() => onSearch('')}>
              <Clear color="primary" />
            </IconButton>
          ),
        }}
        onChange={(e) => onSearch(e.target.value)}
      />
      {isOwner && (
        <DangerToolTip
          title={t('limits_exceeded.term')}
          open={showTooltipLimitation}
          disableHoverListener
          leaveTouchDelay={5000}
          arrow
          onClose={() => setShowTooltipLimitation(false)}
        >
          <Fab size="small" sx={{ ml: 2 }} color="primary" onClick={handleCreateClick}>
            <Add />
          </Fab>
        </DangerToolTip>
      )}
    </Paper>
  );
}
