import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStudySetStore } from 'stores/studySet';
import { ROUTES } from 'routes/routes.config';
import { StudySetRouteParams } from 'routes/routes.types';

interface DeleteStudySetDialogProps {
  open: boolean;
  isParticipant: boolean;
  onClose: () => void;
}

export function DeleteStudySetDialog({ open, isParticipant, onClose }: DeleteStudySetDialogProps) {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userId, setId } = useParams() as StudySetRouteParams;
  const { delete: deleteStudySet, remove } = useStudySetStore();

  const handleSubmit = async () => {
    setLoading(true);
    const isDeleted = isParticipant ? await remove(setId) : await deleteStudySet(setId);
    setLoading(false);
    if (isDeleted) {
      onClose();
      navigate(ROUTES.PROFILE(userId));
    }
  };

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>{t('dialog_delete_set.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {isParticipant ? t('dialog_delete_set.text_remove') : t('dialog_delete_set.text_delete')}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: 3, pt: 0 }}>
        <Button variant="contained" color="secondary" onClick={onClose}>
          {t('common.close')}
        </Button>
        <LoadingButton loading={loading} color="error" variant="contained" onClick={handleSubmit}>
          {t('common.delete')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
