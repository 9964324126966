import { AppBar, Button, Toolbar, Menu, MenuItem } from '@mui/material';
import { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { NavItem } from '../types';

export interface PropsTopNavbar {
  navItems: NavItem[];
}
export function TopNavbar({ navItems }: PropsTopNavbar) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentChildren, setCurrentChildren] = useState<NavItem[]>([]);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, children: NavItem[]) => {
    setAnchorEl(event.currentTarget);
    setCurrentChildren(children);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setCurrentChildren([]);
  };

  const getColor = (path?: string) => {
    return location.pathname === path ? 'primary.main' : 'text.primary';
  };

  return (
    <AppBar sx={{ borderRadius: '8px', mt: '16px' }} position="static" color="inherit">
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-around' }}>
        {navItems.map((item) =>
          item.childrens ? (
            <Fragment key={item.label}>
              <Button
                variant="contained"
                color="secondary"
                startIcon={item.icon}
                onClick={(e) => handleMenuOpen(e, item.childrens as NavItem[])}
              >
                {item.label}
              </Button>
              <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                {currentChildren.map((child) => (
                  <MenuItem
                    key={child.label}
                    onClick={() => {
                      handleMenuClose();
                      if (child.handler) child.handler();
                    }}
                  >
                    {child.icon}
                    {child.label}
                  </MenuItem>
                ))}
              </Menu>
            </Fragment>
          ) : (
            <Button
              key={item.label}
              variant="contained"
              color="secondary"
              sx={{ color: getColor(item.path) }}
              component={Link}
              to={item.path as string}
              startIcon={item.icon}
              onClick={() => {
                if (item.handler) item.handler();
              }}
            >
              {item.label}
            </Button>
          )
        )}
      </Toolbar>
    </AppBar>
  );
}
