import { nanoid } from 'nanoid';
import { RatingItem, UserRating } from './ratings.types';

export const getUserStatsMock = (overrides?: Partial<RatingItem>): RatingItem => ({
  userId: nanoid(),
  firstName: 'Dima',
  lastName: 'Dimochka',
  points: 100,
  ...overrides,
});

export const allStatsMock = [
  getUserStatsMock(),
  getUserStatsMock({
    firstName: 'Diana',
    lastName: 'Dimochka',
    points: 10,
  }),
  getUserStatsMock({
    firstName: 'Di',
    lastName: 'Di',
    points: 80,
  }),
  getUserStatsMock({
    firstName: 'new',
    lastName: 'user',
    points: 120,
  }),
];

export const getCurrentUserStatsMock = (overrides?: Partial<UserRating>): UserRating => ({
  userId: nanoid(),
  points: 100,
  position: 1,
  ...overrides,
});
