import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppStore } from 'stores/app';
import { SERVICE_MSG_KEYS } from 'constants/index';

export function ServiceMessageDialog() {
  const { t } = useTranslation();
  const { setServiceMessageCode, serviceMessage } = useAppStore();
  const handleClose = () => {
    setServiceMessageCode(null);
  };

  return (
    <Dialog fullWidth open={serviceMessage.isShown} onClose={handleClose}>
      <DialogTitle>{t('service_messages.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t(SERVICE_MSG_KEYS[serviceMessage.messageCode as string])}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: 3, pt: 0 }}>
        <Button variant="contained" color="secondary" onClick={handleClose}>
          {t('common.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
