import { useEffect, useState } from 'react';

interface CountdownProps {
  endTime: Date;
}

export function Countdown({ endTime }: CountdownProps) {
  const [timeRemaining, setTimeRemaining] = useState<number>(getTimeRemaining());

  useEffect(() => {
    const interval = setInterval(() => setTimeRemaining(getTimeRemaining()), 1000);
    return () => clearInterval(interval);
  }, [endTime]);

  function getTimeRemaining(): number {
    const timeToFinish = endTime.getTime() - new Date().getTime();
    return timeToFinish > 0 ? timeToFinish : 0;
  }

  const formatTimeUnit = (unit: number): string => (unit < 10 ? `0${unit}` : String(unit));

  const hours = formatTimeUnit(Math.floor(timeRemaining / (1000 * 60 * 60)));
  const minutes = formatTimeUnit(Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60)));
  const seconds = formatTimeUnit(Math.floor((timeRemaining % (1000 * 60)) / 1000));
  return (
    <>
      {hours}:{minutes}:{seconds}
    </>
  );
}
