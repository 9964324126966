import { UserSettingUtil } from 'stores/user';
import { SUPPORTED_LANGUAGES } from 'constants/index';
import { generateGMTOptions } from 'utils';

export const MAX = 1440;
export const MIN = 0;
export const minDistance = 30;
export const marks = [
  {
    value: 0,
    label: '0:00',
  },
  {
    value: 360,
    label: '6:00',
  },
  {
    value: 720,
    label: '12:00',
  },
  {
    value: 1080,
    label: '18:00',
  },
  {
    value: 1440,
    label: '24:00',
  },
];

export const languageSettings: UserSettingUtil = {
  id: 'language',
  name: 'settings.language',
  inputLabel: 'settings.language',
  options: SUPPORTED_LANGUAGES.map((lang) => ({ value: lang, label: `language.${lang}` })),
};

export const settingUtils: UserSettingUtil[] = [
  {
    id: 'timezoneOffset',
    name: 'settings.timezone',
    inputLabel: 'settings.timezone',
    options: generateGMTOptions(),
  },
  {
    id: 'interval',
    name: 'settings.interval.title',
    inputLabel: 'settings.interval.title',
    options: [
      {
        value: 1800,
        label: 'settings.interval.options.every30minutes',
      },
      {
        value: 3600,
        label: 'settings.interval.options.everyHour',
      },
      {
        value: 10800,
        label: 'settings.interval.options.every3Hours',
      },
      {
        value: 36000,
        label: 'settings.interval.options.onceADay',
      },
    ],
  },
];
