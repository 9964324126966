import { Stack, Fab, Typography, Menu, MenuItem, IconButton } from '@mui/material';
import { ChevronLeft, Settings, EditOutlined, Delete, LibraryAdd, Done } from '@mui/icons-material';
import { useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useStudySetStore } from 'stores/studySet';
import { ROUTES } from 'routes/routes.config';
import { StudySetRouteParams } from 'routes/routes.types';
import { JoinStudySetDialog } from 'components/JoinStudySetDialog/JoinStudySetDialog';
import { DeleteStudySetDialog } from './DeleteStudySetDialog';
import { UpdateStudySetDialog } from './UpdateStudySetDialog';

interface DetailsHeaderProps {
  isOwner: boolean;
  isParticipant: boolean;
  isCurrentUserParticipant: boolean;
  isJoinable: boolean;
  name: string;
}

export function DetailsHeader({
  isOwner,
  isParticipant,
  isCurrentUserParticipant,
  isJoinable,
  name,
}: DetailsHeaderProps) {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showJoinDialog, setShowJoinDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { refresh } = useStudySetStore();
  const { setId } = useParams() as StudySetRouteParams;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const canJoin = isJoinable && !isParticipant;
  const externalSetJoined = isJoinable && isParticipant && !isCurrentUserParticipant;

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Fab
        size="small"
        color="secondary"
        sx={{ minWidth: 40 }}
        onClick={() => navigate(ROUTES.BACK)}
      >
        <ChevronLeft />
      </Fab>
      <Typography variant="h2" color="primary" align="center">
        {name}
      </Typography>
      <Stack direction="row" spacing={1}>
        {isOwner && (
          <Fab size="small" color="secondary" onClick={handleMenuOpen}>
            <Settings />
          </Fab>
        )}
        {isCurrentUserParticipant && (
          <IconButton
            size="small"
            sx={{ border: '1px solid', borderColor: 'error.main' }}
            onClick={() => setShowDeleteDialog(true)}
          >
            <Delete color="error" />
          </IconButton>
        )}
        {canJoin && (
          <IconButton size="small" onClick={() => setShowJoinDialog(true)}>
            <LibraryAdd color="primary" />
          </IconButton>
        )}
        {externalSetJoined && <Done />}
        {!isOwner && !isCurrentUserParticipant && !isJoinable && <span className="stub" />}
      </Stack>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() => {
            setShowEditDialog(true);
            setAnchorEl(null);
          }}
        >
          <EditOutlined sx={{ mr: 2 }} />
          <Typography variant="body1">{t('common.edit')}</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setShowDeleteDialog(true);
            setAnchorEl(null);
          }}
        >
          <Delete color="error" sx={{ mr: 2 }} />
          <Typography variant="body1" color="error">
            {t('common.delete')}
          </Typography>
        </MenuItem>
      </Menu>
      <DeleteStudySetDialog
        isParticipant={isCurrentUserParticipant}
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
      />
      <UpdateStudySetDialog open={showEditDialog} onClose={() => setShowEditDialog(false)} />
      <JoinStudySetDialog
        setId={setId}
        open={showJoinDialog}
        onClose={() => setShowJoinDialog(false)}
        onJoined={refresh}
      />
    </Stack>
  );
}
