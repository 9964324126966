import { Paper, Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useUserStore, Trophy } from 'stores/user';
import { ListWrapper } from 'components/commons';
import { TROPHY_TYPES, TROPHY_VARIANTS } from 'constants/index';
import { TrophyItem } from './TrophyItem';

export function UserTrophies() {
  const { t } = useTranslation();
  const { trophies } = useUserStore();

  const createUnacquiredTrophiesList = () => {
    const unacquiredTrophiesList: Trophy[] = [];

    for (const type of TROPHY_TYPES) {
      TROPHY_VARIANTS.forEach((variant, index) => {
        const name = type === 'gold' ? 'golden' : type;
        unacquiredTrophiesList.push({
          info: {
            title: t('trophies.trophy_title'),
            name: `${name} ${variant}`,
            rarity: 0.1 * (TROPHY_VARIANTS.length - index),
            type,
            url: `/trophies/unacquired_${type}.png`,
          },
        });
      });
    }
    return unacquiredTrophiesList;
  };

  const getUserTrophyList = () => {
    const userTrophyMap = new Map(
      trophies?.map((trophy) => [trophy.info.name.toLowerCase(), trophy])
    );
    const finalTrophyList = [];
    const unacquiredTrophies = createUnacquiredTrophiesList();

    for (const unacquiredTrophy of unacquiredTrophies) {
      const userTrophy = userTrophyMap.get(unacquiredTrophy.info.name);
      finalTrophyList.push(userTrophy ?? unacquiredTrophy);
    }
    return finalTrophyList;
  };

  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h2" color="primary" align="center">
        {t('trophies.block_title')}
      </Typography>
      <ListWrapper list={trophies} emptyListMsg={t('trophies.no_trophies_msg')}>
        <Grid container maxWidth="400px" m="0 auto" rowSpacing={2}>
          {getUserTrophyList().map((trophy) => (
            <TrophyItem key={trophy.info.name} trophy={trophy} />
          ))}
        </Grid>
      </ListWrapper>
    </Paper>
  );
}
