import { Stack, Fab, Typography, Paper } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCardStore } from 'stores/card';
import {
  useStudySetStore,
  useIsCurrentUserOwner,
  useIsCurrentUserParticipant,
} from 'stores/studySet';
import { StudySetRouteParams } from 'routes/routes.types';
import { ListWrapper } from 'components/commons';
import { TermsToolbar } from './TermsToolbar/TermsToolbar';
import { TermsTable } from './TermsTable/TermsTable';
import { TermCardManager } from './TermCardManager/TermCardManager';

export function TermsBlock() {
  const { userId } = useParams() as StudySetRouteParams;
  const { studySet } = useStudySetStore();
  const { openCardManager, showCardManager } = useCardStore();
  const isCurrentUserOwner = useIsCurrentUserOwner();
  const isCurrentUserParticipant = useIsCurrentUserParticipant(userId);
  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState<Set<string>>(new Set());
  const { t } = useTranslation();

  useEffect(() => {
    if (studySet?.cards) {
      const cardIds = new Set(studySet.cards.map(({ id }) => id));
      const updatedSelected = new Set(Array.from(selected).filter((id) => cardIds.has(id)));
      if (updatedSelected.size !== selected.size) setSelected(updatedSelected);
    }
  }, [studySet?.cards]);

  useEffect(() => {
    if (!showCardManager) setSelected(new Set());
  }, [showCardManager]);

  const filteredCards =
    studySet?.cards.filter(
      (card) =>
        card.terms.some((term) => term.toLowerCase().includes(search.trim().toLowerCase())) ||
        card.relatedTerms.some((term) => term.toLowerCase().includes(search.trim().toLowerCase()))
    ) || null;

  const handleSelectAll = (checked: boolean): void => {
    setSelected(new Set(checked ? filteredCards?.map(({ id }) => id) : []));
  };

  const handleSelect = (id: string): void => {
    if (!isCurrentUserOwner && !isCurrentUserParticipant) return;
    const newSelected = new Set(selected);
    newSelected.has(id) ? newSelected.delete(id) : newSelected.add(id);
    setSelected(newSelected);
  };

  const emptyListBlock = isCurrentUserOwner ? (
    <Paper>
      <Stack spacing={2} alignItems="center" textAlign="center" py={2}>
        <Typography variant="h2" color="text.primary">
          {t('set.first_term')}
        </Typography>
        <Fab size="small" color="primary" className="pulse" onClick={() => openCardManager()}>
          <Add />
        </Fab>
      </Stack>
    </Paper>
  ) : (
    t('set.no_terms_msg')
  );

  return (
    <>
      {!!studySet?.cards.length && (
        <TermsToolbar
          selected={selected}
          search={search}
          isOwner={isCurrentUserOwner}
          onSearchChange={setSearch}
        />
      )}
      {!showCardManager && (
        <ListWrapper
          list={filteredCards}
          isFiltered={!!search}
          emptyListMsg={emptyListBlock}
          noMatchMsg={t('set.no_matches')}
        >
          <TermsTable
            search={search}
            cards={filteredCards || []}
            selected={selected}
            isOwner={isCurrentUserOwner}
            isParticipant={isCurrentUserParticipant}
            onSelectAll={handleSelectAll}
            onSelect={handleSelect}
          />
        </ListWrapper>
      )}
      <TermCardManager />
    </>
  );
}
