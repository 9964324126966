import { Box, Chip, TextField } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';

const DEFAULT_ENTITY_LIMIT = 2;

interface CardEntityProps {
  id: string;
  type: string;
  required?: boolean;
  initialValues?: string[];
  isMultiValues?: boolean;
  onChange: (value: string[]) => void;
}

export function CardEntity({
  id,
  type,
  initialValues = [],
  isMultiValues,
  required,
  onChange,
}: CardEntityProps) {
  const [value, setValue] = useState(initialValues[0] || '');
  const [entities, setEntities] = useState<string[]>(initialValues.slice(1));
  const { t } = useTranslation();

  useEffect(() => {
    onChange([value, ...entities].filter(Boolean));
  }, [entities, value]);

  const handleAddEntity = () => {
    if (value.trim() !== '') {
      setEntities((prev) => [...prev, value.trim()]);
      setValue('');
    }
  };

  const handleDeleteEntity = (index: number) => {
    setEntities((prev) => prev.filter((_, i) => i !== index));
  };

  const handleChipClick = (chipValue: string, index: number) => {
    if (value) handleAddEntity();
    setValue(chipValue);
    handleDeleteEntity(index);
  };

  const isShownAddButton =
    isMultiValues && !!value.length && entities.length < DEFAULT_ENTITY_LIMIT;

  return (
    <Box mt={1}>
      <TextField
        id={id}
        fullWidth
        label={t(`card.${type}.label`)}
        placeholder={t(`card.${type}.placeholder`)}
        size="small"
        required={required}
        InputProps={{
          endAdornment: isShownAddButton && <Add color="primary" onClick={handleAddEntity} />,
        }}
        multiline
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <Box mb={1} display="flex" flexWrap="wrap" gap={1}>
        {value && isMultiValues && (
          <Chip label={value} color="secondary" onDelete={() => setValue('')} sx={{ mt: 0.5 }} />
        )}
        {entities.map((entity, index) => (
          <Chip
            key={index}
            label={entity}
            color="secondary"
            sx={{ mt: 0.5 }}
            onDelete={() => handleDeleteEntity(index)}
            onClick={() => handleChipClick(entity, index)}
          />
        ))}
      </Box>
    </Box>
  );
}
