import { Table, TableBody, TableRow, TableCell, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useRatingsStore } from 'stores/ratings';
import { ListWrapper } from 'components/commons';
import { ROUTES } from 'routes/routes.config';
import { ReactComponent as IconGoldTrophy } from 'assets/images/leaderboard/gold_trophy.svg';
import { ReactComponent as IconSilverTrophy } from 'assets/images/leaderboard/silver_trophy.svg';
import { ReactComponent as IconBronzeTrophy } from 'assets/images/leaderboard/bronze_trophy.svg';

export function LeaderboardTable() {
  const { t } = useTranslation();
  const { generalRatings, userRatings } = useRatingsStore();
  const trophyIcons = [
    <IconGoldTrophy key="gold" />,
    <IconSilverTrophy key="silver" />,
    <IconBronzeTrophy key="bronze" />,
  ];
  return (
    <ListWrapper list={generalRatings} emptyListMsg={t('leaderboard.no_points_text')}>
      <Table
        sx={{
          backgroundColor: 'background.paper',
          borderRadius: '0 0 8px 8px',
          boxShadow: '0 9px 14px #171428',
        }}
      >
        <TableBody>
          {generalRatings?.map(({ firstName, lastName, points, userId }, index) => (
            <TableRow key={index} sx={{ alignItems: 'center', fontSize: 14 }}>
              <TableCell sx={{ border: 0, width: 30 }}>{trophyIcons[index]}</TableCell>
              <TableCell align="left">{index + 1}</TableCell>
              <TableCell>
                <Link
                  component={RouterLink}
                  underline="none"
                  color={userRatings?.userId === userId ? 'primary' : 'inherit'}
                  to={ROUTES.PROFILE(userId)}
                >
                  {firstName} {lastName}
                </Link>
              </TableCell>
              <TableCell align="right">{points}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </ListWrapper>
  );
}
