import { Typography, Box, FormControlLabel, Switch, CircularProgress } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserStore } from 'stores/user';
import { useAppStore } from 'stores/app';

export function IntervalTrainingSwitch() {
  const { t } = useTranslation();
  const { settings, updateSettings } = useUserStore();
  const { setNotificationBar } = useAppStore();
  const [isEnabled, setIsEnabled] = useState(settings?.trainingEnabled);
  const [loading, setLoading] = useState(false);

  const isIntervalTrainingDisabled = !settings?.timezoneOffset || !settings?.interval;

  async function handleChange(checked: boolean) {
    if (isIntervalTrainingDisabled) return false;
    setLoading(true);
    setIsEnabled(checked);
    const status = await updateSettings(checked, 'trainingEnabled');
    setLoading(false);
    if (status === 'success') {
      setNotificationBar(status, t(`settings.intervalTrainingSection.is${checked ? 'On' : 'Off'}`));
    } else {
      setNotificationBar(status, t('notificationBar.default.error'));
      setIsEnabled(!checked);
    }
  }

  function getLabel() {
    if (loading) {
      return <CircularProgress size={20} />;
    } else return t(`settings.${isEnabled ? 'on' : 'off'}`);
  }

  return (
    settings && (
      <Box width="100%" maxWidth={600}>
        <Typography>{t('settings.intervalTrainingSection.title')}</Typography>
        {!settings.timezoneOffset && (
          <Typography variant="caption" display="block" color="error">
            {`${t('settings.fillField')} ${t('settings.timezone')}`}
          </Typography>
        )}
        {!settings.interval && (
          <Typography variant="caption" display="block" color="error">
            {`${t('settings.fillField')} ${t('settings.interval.title')}`}
          </Typography>
        )}
        <FormControlLabel
          control={
            <Switch
              disabled={isIntervalTrainingDisabled}
              checked={isEnabled}
              onChange={({ target }) => handleChange(target.checked)}
            />
          }
          label={getLabel()}
        />
      </Box>
    )
  );
}
