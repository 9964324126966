import { graphql } from 'services/graphql';

export const deleteCardsMutation = graphql`
  mutation deleteUserCards($userSetId: String!, $userCardIds: [String]!) {
    deleteUserCards(userSetId: $userSetId, userCardIds: $userCardIds) {
      isSuccess
      errors {
        code
        message
      }
    }
  }
`;

export const updateCardsLearningLevelMutation = graphql`
  mutation setCardsLearningLevel(
    $userSetId: String!
    $userCardIds: [String]!
    $learningLevel: Int!
  ) {
    setCardsLearningLevel(
      userSetId: $userSetId
      userCardIds: $userCardIds
      learningLevel: $learningLevel
    ) {
      isSuccess
      errors {
        code
      }
    }
  }
`;

export const createCardsMutation = graphql`
  mutation createUserCards($userSetId: String!, $cards: [CreateUserCardInput]!) {
    createUserCards(userSetId: $userSetId, cards: $cards) {
      userCards {
        id
        terms
        relatedTerms
        learningLevel
        definitions
        examples
      }
      errors {
        code
        message
        field
      }
    }
  }
`;

export const updateCardsMutation = graphql`
  mutation updateUserCards($userSetId: String!, $cards: [UpdateUserCardInput]!) {
    updateUserCards(userSetId: $userSetId, cards: $cards) {
      userCards {
        id
        terms
        relatedTerms
        learningLevel
        definitions
        examples
      }
      errors {
        code
        message
        field
      }
    }
  }
`;
