import { graphql } from 'services/graphql';

export const getStudySetQuery = graphql`
  query userSet($userSetId: String!) {
    userSet(userSetId: $userSetId) {
      id
      name
      description
      progress
      level
      totalCards
      numLearnedCards
      isPublic
      isActive
      owner {
        id
        firstName
        lastName
      }
      cards {
        id
        terms
        relatedTerms
        hasDefinitions
        definitions
        hasExamples
        examples
        learningLevel
      }
      requestUserMeta {
        isParticipant
        isJoinable
      }
    }
  }
`;
