import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slider,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCardStore } from 'stores/card';
import { StudySetRouteParams } from 'routes/routes.types';

interface UpdateLearningLevelDialogProps {
  open: boolean;
  ids: string[];
  onClose: () => void;
}

export function UpdateLearningLevelDialog({ open, ids, onClose }: UpdateLearningLevelDialogProps) {
  const [loading, setLoading] = useState(false);
  const [learningLevel, setLearningLevel] = useState(5);
  const { t } = useTranslation();
  const { setId } = useParams() as StudySetRouteParams;
  const { updateLearningLevel } = useCardStore();

  const getSliderColor = (level: number): 'error' | 'warning' | 'primary' => {
    const progress = level * 10;
    if (progress <= 30) return 'error';
    if (progress <= 70) return 'warning';
    return 'primary';
  };

  const handleSubmit = async () => {
    setLoading(true);
    await updateLearningLevel(setId, ids, learningLevel);
    setLoading(false);
    onClose();
  };

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>{t('dialog_learning_level.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('dialog_learning_level.text')}</DialogContentText>
        <Slider
          valueLabelDisplay="auto"
          defaultValue={5}
          step={1}
          marks
          min={0}
          max={10}
          color={getSliderColor(learningLevel)}
          sx={{ pt: 4 }}
          value={learningLevel}
          onChange={(_, newValue) => setLearningLevel(newValue as number)}
        />
      </DialogContent>
      <DialogActions sx={{ p: 3, pt: 0 }}>
        <Button variant="contained" color="secondary" onClick={onClose}>
          {t('common.close')}
        </Button>
        <LoadingButton loading={loading} variant="contained" onClick={handleSubmit}>
          {t('common.save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
