import { Box, Typography, TableCell, Tooltip, List, ListItem } from '@mui/material';
import { Close, Check } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface AddOnsCellProps {
  examples: string[];
  definitions: string[];
}

const popper = {
  modifiers: [
    {
      name: 'offset',
      options: { offset: [0, -14] },
    },
  ],
};

export function AddOnsCell({ examples, definitions }: AddOnsCellProps) {
  const { t } = useTranslation();

  const generateTooltipContent = (title: string, items: string[]) => (
    <Box sx={{ textAlign: 'left' }}>
      <Typography variant="subtitle2">{title}:</Typography>
      <List>
        {items.map((item, index) => (
          <ListItem key={index} sx={{ fontSize: 12 }} disablePadding>
            {index + 1}. {item}
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <TableCell sx={{ p: 1 }} onClick={(e) => e.stopPropagation()}>
      {examples.length ? (
        <Tooltip
          placement="top-start"
          leaveTouchDelay={10000}
          enterTouchDelay={0}
          title={generateTooltipContent(t('set.table_examples'), examples)}
          slotProps={{ popper }}
        >
          <Typography
            variant="body2"
            sx={{ display: 'flex', alignItems: 'center', color: 'primary.main' }}
          >
            <Check fontSize="small" sx={{ mr: 0.5 }} />
            {t('set.table_examples')}
          </Typography>
        </Tooltip>
      ) : (
        <Typography
          variant="body2"
          sx={{ display: 'flex', alignItems: 'center', color: 'error.main' }}
        >
          <Close fontSize="small" sx={{ mr: 0.5 }} />
          {t('set.table_examples')}
        </Typography>
      )}
      {definitions.length ? (
        <Tooltip
          placement="bottom-start"
          leaveTouchDelay={10000}
          enterTouchDelay={0}
          title={generateTooltipContent(t('set.table_definitions'), definitions)}
          slotProps={{ popper }}
        >
          <Typography
            variant="body2"
            sx={{ display: 'flex', alignItems: 'center', color: 'primary.main' }}
          >
            <Check fontSize="small" sx={{ mr: 0.5 }} />
            {t('set.table_definitions')}
          </Typography>
        </Tooltip>
      ) : (
        <Typography
          variant="body2"
          sx={{ display: 'flex', alignItems: 'center', color: 'error.main' }}
        >
          <Close fontSize="small" sx={{ mr: 0.5 }} />
          {t('set.table_definitions')}
        </Typography>
      )}
    </TableCell>
  );
}
