import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState, useEffect, useRef } from 'react';

export const useDynamicHeight = (dependency: boolean) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [height, setHeight] = useState('auto');

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const containerTop = containerRef.current.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        const spaceToBottom = isMobile ? 76 : 16;
        setHeight(`${windowHeight - containerTop - spaceToBottom}px`);
      }
    };

    setTimeout(handleResize, 350);
  }, [dependency]);

  return { containerRef, height };
};
