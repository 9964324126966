import { Outlet } from 'react-router-dom';
import { Container, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Navbar } from './Navbar';
import { ServiceMessageDialog } from './ServiceMessageDialog/ServiceMessageDialog';
import { NotificationBar } from './NotificationBar/NotificationBar';

export function AppLayout() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Navbar />
      <Container sx={{ pb: isMobile ? 9 : 0, pt: { xs: 3, sm: 0 } }}>
        <Outlet />
      </Container>
      <ServiceMessageDialog />
      <NotificationBar />
    </>
  );
}
