import { Box, DialogContent } from '@mui/material';
import { useState, useEffect, useRef, UIEvent } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { ScrollToTopButton } from 'components/commons';
import { Mode, CardTemplate } from 'stores/card';
import { CardItem } from './CardItem';

interface TermCardManagerBodyProps {
  mode: Mode;
  cards: CardTemplate[];
  showCardManager: boolean;
}

const SCROLL_TOP_THRESHOLD = 200;

export function TermCardManagerBody({ mode, cards, showCardManager }: TermCardManagerBodyProps) {
  const [contentHeight, setContentHeight] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);
  const virtuosoRef = useRef(null);
  const isShowScrollToTopButton = scrollPosition > SCROLL_TOP_THRESHOLD;

  useEffect(() => {
    if (showCardManager) {
      requestAnimationFrame(() => {
        if (contentRef.current) setContentHeight(contentRef.current.clientHeight);
      });
    }
  }, [showCardManager]);

  const handleScroll = (e: UIEvent<HTMLElement>) => {
    const currentPosition = (e.currentTarget as HTMLElement)?.scrollTop;
    setScrollPosition(currentPosition);
  };

  return (
    <>
      <DialogContent
        ref={contentRef}
        sx={{ bgcolor: 'background.default', overflowY: 'hidden', pt: 0, px: 1 }}
      >
        <Virtuoso
          ref={virtuosoRef}
          style={{ width: '100%', height: `${contentHeight}px` }}
          data={cards}
          itemContent={(index, card) => (
            <Box
              key={card.id}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                ...(!index && { pt: 2 }),
                ...(index === cards.length - 1 && { pb: `${contentHeight / 2}px` }),
              }}
            >
              <CardItem key={card.id} initialCard={card} mode={mode} />
            </Box>
          )}
          onScroll={handleScroll}
        />
      </DialogContent>
      {isShowScrollToTopButton && <ScrollToTopButton containerRef={virtuosoRef} />}
    </>
  );
}
