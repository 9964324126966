import { Box, Card, CardContent, CardActionArea, Typography } from '@mui/material';
import { AddCircleOutlineOutlined } from '@mui/icons-material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserStudySetsLimitation } from 'stores/user';
import { DangerToolTip } from 'components/commons';
import { AddSetDialog } from './AddSetDialog';

export function AddSetCard() {
  const [shownAddSetDialog, setShownAddSetDialog] = useState(false);
  const [showTooltipLimitation, setShowTooltipLimitation] = useState(false);
  const isUserSetsNotExceeded = useUserStudySetsLimitation();
  const { t } = useTranslation();

  const handleCreateClick = () => {
    isUserSetsNotExceeded ? setShownAddSetDialog(true) : setShowTooltipLimitation(true);
  };

  return (
    <>
      <DangerToolTip
        title={t('limits_exceeded.studySet')}
        open={showTooltipLimitation}
        disableHoverListener
        placement="top"
        leaveTouchDelay={5000}
        arrow
        onClose={() => setShowTooltipLimitation(false)}
      >
        <Card
          sx={{ minWidth: 275, border: 2, borderColor: 'primary.main', bgcolor: 'secondary.main' }}
        >
          <CardActionArea onClick={handleCreateClick}>
            <CardContent
              sx={{
                minHeight: 96,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h5" color="primary.main" sx={{ lineHeight: 1.2 }}>
                  {t('profile_sets.add_new_set_btn')}
                </Typography>
                <AddCircleOutlineOutlined color="primary" fontSize="large" />
              </Box>
            </CardContent>
          </CardActionArea>
        </Card>
      </DangerToolTip>
      <AddSetDialog open={shownAddSetDialog} onClose={() => setShownAddSetDialog(false)} />
    </>
  );
}
