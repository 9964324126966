import ReactDOM from 'react-dom/client';
import { AppProviders } from 'providers';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <AppProviders>
    <App />
  </AppProviders>
);
