import { Box, Typography, Slider } from '@mui/material';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface LearningSliderProps {
  value: number;
  onChange: (val: number) => void;
}

export function LearningSlider({ value, onChange }: LearningSliderProps) {
  const { t } = useTranslation();
  const [progress, setProgress] = useState(value);

  const progressLevels = [
    { maxProgress: 0, level: 'elemental' },
    { maxProgress: 3, level: 'beginning' },
    { maxProgress: 7, level: 'medium' },
    { maxProgress: 9, level: 'advanced' },
    { maxProgress: 10, level: 'peak' },
  ];

  useEffect(() => onChange(progress), [progress]);

  const getProgressColor = (): 'error' | 'warning' | 'primary' => {
    if (progress <= 3) return 'error';
    if (progress <= 7) return 'warning';
    return 'primary';
  };

  const getProgressLevel = (): string => {
    const level = progressLevels.find(({ maxProgress }) => progress <= maxProgress);
    return level ? level.level : 'unknown';
  };

  return (
    <Box px={1} sx={{ lineHeight: 1, textAlign: 'right' }}>
      <Slider
        step={1}
        min={0}
        max={10}
        color={getProgressColor()}
        sx={{ py: 2 }}
        value={progress}
        onChange={(_, val) => setProgress(val as number)}
      />
      <Typography variant="caption" color={`${getProgressColor()}.main`}>
        {t(`learningLevel.${getProgressLevel()}`)}
      </Typography>
    </Box>
  );
}
