import { Slider, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserStore } from 'stores/user';
import { transformTimeToMinutes, transformMinutesToTimeString } from 'utils';
import { useDebounce } from 'hooks';
import { MAX, MIN, minDistance, marks } from './constants';

interface PeriodSliderProps {
  startTime: string;
  endTime: string;
}

export function PeriodSlider({ startTime, endTime }: PeriodSliderProps) {
  const { t } = useTranslation();
  const { updateSettings } = useUserStore();
  const [value, setValue] = useState([
    transformTimeToMinutes(startTime),
    transformTimeToMinutes(endTime),
  ]);

  const handleChange = (_: Event, newValue: number | number[], activeThumb: number) => {
    if (!Array.isArray(newValue)) return;
    const [firstThumbValue, secondThumbValue] = newValue;
    if (secondThumbValue - firstThumbValue < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(firstThumbValue, MAX - minDistance);
        setValue([clamped, clamped + minDistance]);
        debouncedUpdate(clamped, clamped + minDistance);
      } else {
        const clamped = Math.max(secondThumbValue, minDistance);
        setValue([clamped - minDistance, clamped]);
        debouncedUpdate(clamped - minDistance, clamped);
      }
    } else {
      setValue(newValue);
      debouncedUpdate(firstThumbValue, secondThumbValue);
    }
  };

  const debouncedUpdate = useDebounce((start: number, end: number) => {
    updateSettings(transformMinutesToTimeString(start), 'startTime');
    updateSettings(transformMinutesToTimeString(end), 'endTime');
  }, 1000);

  return (
    <Stack width={'92%'} maxWidth={600}>
      <Typography p={1} pl={0} sx={{ alignSelf: 'flex-start' }}>
        {t('settings.period.title')}
      </Typography>
      <Slider
        step={minDistance}
        min={MIN}
        max={MAX}
        marks={marks}
        valueLabelDisplay="auto"
        value={value}
        disableSwap
        valueLabelFormat={transformMinutesToTimeString}
        onChange={handleChange}
      />
    </Stack>
  );
}
