const development: boolean = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export function isDev(): boolean {
  return development;
}

export const generateGMTOptions = () => {
  const values = Array.from({ length: 13 }, (_, i) => i).concat(
    Array.from({ length: 12 }, (_, i) => -1 - i)
  );
  return values.map((value) => ({
    value,
    label: `GMT${value >= 0 ? `+${value}` : value}`,
  }));
};

export const transformTimeToMinutes = (time: string) => {
  const [hours, minutes] = time.split(':').map(Number);
  return hours * 60 + minutes;
};

export const transformMinutesToTimeString = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(remainingMinutes).padStart(2, '0');
  return `${formattedHours}:${formattedMinutes}`;
};
