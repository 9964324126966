import { Paper, Stack, Typography, Grid, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useUserStore } from 'stores/user';
import { OrbitSpinner } from 'components/commons';
import { SettingItem } from './SettingItem';
import { PeriodSlider } from './PeriodSlider';
import { IntervalTrainingSwitch } from './IntervalTrainingSwitch';
import { settingUtils, languageSettings } from './constants';

export function Settings() {
  const { t } = useTranslation();
  const { settings, updateSettings } = useUserStore();

  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h2" color="primary" align="center" pb={2}>
        {t('profile.tab_settings')}
      </Typography>
      {settings ? (
        <Stack direction="column" spacing={3} maxWidth="900px" m="0 auto" alignItems="center">
          <Typography fontSize="18px" width="100%" maxWidth="600px">
            {t('settings.interfaceSection.title')}
          </Typography>
          <SettingItem
            key={languageSettings.name}
            name={languageSettings.name}
            inputLabel={languageSettings.inputLabel}
            value={settings[languageSettings.id]}
            type={languageSettings.id}
            options={languageSettings.options}
            onChange={updateSettings}
          />
          <Divider sx={{ m: 2, width: '100%', maxWidth: '600px' }} />
          <Typography fontSize="18px" width="100%" maxWidth="600px">
            {t('settings.intervalTrainingSection.title')}
          </Typography>
          {settingUtils.map(({ name, inputLabel, id, options }) => (
            <SettingItem
              key={name}
              name={name}
              inputLabel={inputLabel}
              value={settings[id]}
              type={id}
              options={options}
              onChange={updateSettings}
            />
          ))}
          <PeriodSlider
            startTime={settings.startTime ?? '10:00'}
            endTime={settings.endTime ?? '22:00'}
          />
          <IntervalTrainingSwitch />
        </Stack>
      ) : (
        <Grid display="flex" justifyContent="center" alignItems="center" sx={{ height: '200px' }}>
          <OrbitSpinner />
        </Grid>
      )}
    </Paper>
  );
}
