import { useState, useCallback, useRef } from 'react';
import { Box, Button, Collapse, Divider, Paper, Typography, Stack } from '@mui/material';
import { ExpandLess } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useCardStore, CardTemplate, Mode } from 'stores/card';
import { LearningSlider } from 'components/commons';
import { CardEntity } from './CardEntity';

interface CardItemProps {
  initialCard: CardTemplate;
  mode: Mode;
}
export function CardItem({ initialCard }: CardItemProps) {
  const { updateCardToSubmitById, removeCardById } = useCardStore();
  const [card, setCard] = useState<CardTemplate>(initialCard);
  const [show, setShow] = useState(false);
  const cardRef = useRef<HTMLDivElement>(null);
  const [isMounted, setIsMounted] = useState(false);
  const { t } = useTranslation();

  const handleChange = useCallback(
    (entityType: string, value: string[] | number) => {
      const updatedCard = { ...card, [entityType]: value };
      updateCardToSubmitById(card.id, updatedCard);
      setCard(updatedCard);
    },
    [card, updateCardToSubmitById]
  );

  const handleToggle = () => {
    if (show) {
      setShow(false);
      setTimeout(() => setIsMounted(false), 300);
    } else {
      setIsMounted(true);
      setTimeout(() => setShow(true), 10);
    }
  };

  const renderCardEntity = (type: string, initialValues: string[], isMultiValues = false) => (
    <CardEntity
      id={card.id + type}
      type={type}
      initialValues={initialValues}
      isMultiValues={isMultiValues}
      required={['terms', 'relatedTerms'].includes(type)}
      onChange={(values) => handleChange(type, values)}
    />
  );

  return (
    <Paper sx={{ p: 1, mb: 2, width: '100%', maxWidth: 600 }} ref={cardRef}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body1">{t('card.title')}</Typography>
        <Button size="small" color="error" onClick={() => removeCardById(card.id)}>
          {t('common.close')}
        </Button>
      </Stack>
      {renderCardEntity('terms', initialCard.terms)}
      <Divider sx={{ m: 2 }} />
      {renderCardEntity('relatedTerms', initialCard.relatedTerms)}
      <Collapse in={show}>
        {isMounted && (
          <>
            <Typography variant="body1">{t('card.add-ons')}</Typography>
            {renderCardEntity('definitions', initialCard.definitions, true)}
            <Divider sx={{ m: 2 }} />
            {renderCardEntity('examples', initialCard.examples, true)}
            <LearningSlider
              value={initialCard.learningLevel}
              onChange={(val) => handleChange('learningLevel', val)}
            />
          </>
        )}
      </Collapse>
      <Box display="flex" justifyContent="center" mt={1}>
        <Button variant="outlined" size="small" onClick={handleToggle}>
          {show ? <ExpandLess /> : t('card.add-ons')}
        </Button>
      </Box>
    </Paper>
  );
}
