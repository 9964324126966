import { Box, Typography, LinearProgress } from '@mui/material';

interface ProgressLineProps {
  progress?: number;
  inline?: boolean;
  colored?: boolean;
  size?: 'small' | 'normal';
}

interface SizeStyle {
  textSize: 'caption' | 'body1';
  lineHeight: number;
}

const PROGRESS_THRESHOLDS = {
  error: 33,
  warning: 66,
};

const SIZE_STYLES: { [key in 'small' | 'normal']: SizeStyle } = {
  small: {
    textSize: 'caption',
    lineHeight: 4,
  },
  normal: {
    textSize: 'body1',
    lineHeight: 6,
  },
};

const getColor = (colored: boolean, progress: number): 'error' | 'warning' | 'primary' => {
  if (!colored) return 'primary';
  if (progress <= PROGRESS_THRESHOLDS.error) return 'error';
  if (progress <= PROGRESS_THRESHOLDS.warning) return 'warning';
  return 'primary';
};

export function ProgressLine({
  inline = false,
  colored = false,
  progress = 0,
  size = 'normal',
}: ProgressLineProps) {
  const styles = SIZE_STYLES[size];
  const color = getColor(colored, progress);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: inline ? 'row' : 'column',
        alignItems: inline ? 'center' : 'initial',
      }}
    >
      <LinearProgress
        sx={{ width: '100%', height: styles.lineHeight }}
        color={color}
        variant="determinate"
        value={progress}
      />
      <Typography sx={{ pl: inline ? 2 : 0, color: `${color}.main` }} variant={styles.textSize}>
        {progress}%
      </Typography>
    </Box>
  );
}
