import { Link, Collapse, List, ListItem, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { ROUTES } from 'routes/routes.config';
import { User } from 'stores/user';

interface CollapsedListProps {
  show: boolean;
  owner: User;
  isOwner: boolean;
  isActive: boolean;
  isPublic: boolean;
  description: string;
}

export function CollapsedList({
  show,
  owner,
  isOwner,
  isActive,
  isPublic,
  description,
}: CollapsedListProps) {
  const { t } = useTranslation();

  return (
    <Collapse in={show}>
      <List sx={{ py: 0, mt: 1 }}>
        {!!description && (
          <ListItem sx={{ p: 0 }}>
            <ListItemText
              sx={{ flex: 'none', minWidth: 120, pr: 2 }}
              secondary={t('set.description_title')}
            />
            <ListItemText primary={description} />
          </ListItem>
        )}
        <ListItem sx={{ p: 0 }}>
          <ListItemText
            sx={{ flex: 'none', minWidth: 120, pr: 2 }}
            secondary={t('set.creator_title')}
          />
          {isOwner ? (
            <ListItemText primary={t('set.me')} />
          ) : (
            <Link component={RouterLink} to={ROUTES.PROFILE(owner.id)}>
              {owner.firstName}
            </Link>
          )}
        </ListItem>
        <ListItem sx={{ p: 0 }}>
          <ListItemText
            sx={{ flex: 'none', minWidth: 120, pr: 2 }}
            secondary={t('set.state_title')}
          />
          <ListItemText
            sx={{ color: isActive ? 'primary.main' : 'text.primary' }}
            primary={isActive ? t('set.active') : t('set.not_active')}
          />
        </ListItem>
        <ListItem sx={{ p: 0 }}>
          <ListItemText
            sx={{ flex: 'none', minWidth: 120, pr: 2 }}
            secondary={t('set.accessibility_title')}
          />
          <ListItemText primary={isPublic ? t('set.public') : t('set.private')} />
        </ListItem>
      </List>
    </Collapse>
  );
}
