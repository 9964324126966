import { create } from 'zustand';
import i18n from 'services/i18n';
import { authViaTelegramWebApp, getAuthUser } from 'graphql/auth';
import { User, getUserMock } from 'stores/user';
import { isDev } from 'utils';

interface AuthState {
  user: User | null;
}

interface AuthActions {
  authorize: (initData: string) => Promise<void>;
  getAuthUser: () => Promise<void>;
  reset: () => void;
}

const initialState: AuthState = {
  user: null,
};

export const useAuthStore = create<AuthState & AuthActions>((set) => ({
  ...initialState,
  authorize: async (initData: string) => {
    try {
      await authViaTelegramWebApp(initData);
    } catch (error) {
      console.error('authorize error:', error);
      set(initialState);
    }
  },
  getAuthUser: async () => {
    try {
      const { user } = await getAuthUser();
      if (user) {
        const { language: currentLanguage } = i18n;
        if (user.language !== currentLanguage) i18n.changeLanguage(user.language);
        set({ user });
      }
    } catch (error) {
      console.error('getAuthUser error:', error);
      if (isDev()) {
        set({ user: getUserMock() });
        return;
      }
      console.error('getAuthUser error:', error);
      set(initialState);
    }
  },
  reset: () => set(initialState),
}));
