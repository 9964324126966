import { AppBar, Box, Button, Dialog, Fab, DialogActions, Stack, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useCardStore, useNotSavedCardIds } from 'stores/card';
import { useCardsLimitation } from 'stores/studySet';
import { DangerToolTip } from 'components/commons';
import { StudySetRouteParams } from 'routes/routes.types';
import { TermCardManagerBody } from './TermCardManagerBody';

export function TermCardManager() {
  const { mode, cardsToSubmit, showCardManager, closeCardManager, addCard, create, update } =
    useCardStore();
  const notSavedCardIds = useNotSavedCardIds();
  const [showTooltipLimitation, setShowTooltipLimitation] = useState(false);
  const [loading, setLoading] = useState(false);
  const isTermsLimitNotExceeded = useCardsLimitation();
  const { t } = useTranslation();
  const { setId } = useParams() as StudySetRouteParams;

  useEffect(() => {
    if (!cardsToSubmit.length) closeCardManager();
  }, [cardsToSubmit.length]);

  const handleCreateClick = () => {
    isTermsLimitNotExceeded(cardsToSubmit.length) ? addCard() : setShowTooltipLimitation(true);
  };

  const handleSave = async () => {
    setLoading(true);
    mode === 'creation' ? await create(setId) : await update(setId);
    setLoading(false);
  };

  return (
    <Dialog fullScreen scroll="paper" open={showCardManager} onClose={closeCardManager}>
      <AppBar sx={{ px: 2, py: 1, bgcolor: 'background.paper', position: 'static' }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Box sx={{ lineHeight: 1 }}>
            <Typography variant="h2" color="text.primary">
              {t('cardsManager.title')}
            </Typography>
            <Typography
              variant="caption"
              color={notSavedCardIds.length ? 'warning.main' : 'primary'}
            >
              {notSavedCardIds.length
                ? t('cardsManager.sub_title_not_saved', { count: notSavedCardIds.length })
                : t('cardsManager.sub_title_saved')}
            </Typography>
          </Box>
          {mode === 'creation' && (
            <DangerToolTip
              title={t('limits_exceeded.term')}
              open={showTooltipLimitation}
              disableHoverListener
              leaveTouchDelay={5000}
              arrow
              onClose={() => setShowTooltipLimitation(false)}
            >
              <Fab size="small" color="primary" onClick={handleCreateClick}>
                <Add />
              </Fab>
            </DangerToolTip>
          )}
        </Stack>
      </AppBar>
      <TermCardManagerBody mode={mode} cards={cardsToSubmit} showCardManager={showCardManager} />
      <DialogActions sx={{ px: 2, py: 1, justifyContent: 'space-between' }}>
        <Button variant="contained" color="secondary" onClick={closeCardManager}>
          {t('common.close')}
        </Button>
        <LoadingButton
          disabled={!notSavedCardIds.length}
          loading={loading}
          variant="contained"
          onClick={handleSave}
        >
          {cardsToSubmit.length > 1 ? t('common.saveAll') : t('common.save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
