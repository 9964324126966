import { Navigate, createBrowserRouter } from 'react-router-dom';
import { AppLayout } from 'components/AppLayout';
import { Leaderboard, Profile, StudySet } from 'pages';
import { ROUTES } from './routes.config';

export const routes = createBrowserRouter([
  {
    path: '/',
    element: <AppLayout />,
    children: [
      { path: '/', element: <Navigate to={ROUTES.LEADERBOARD} /> },
      { path: ROUTES.LEADERBOARD, element: <Leaderboard /> },
      { path: ROUTES.PROFILE(), element: <Profile /> },
      { path: ROUTES.STUDY_SET(), element: <StudySet /> },
      { path: '*', element: <Navigate to={ROUTES.ROOT} /> },
    ],
  },
]);
