import { Paper, Typography, Box, Tabs, Tab, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRatingsStore } from 'stores/ratings';
import { useIsAuthorized } from 'stores/auth';
import { Countdown } from 'components/commons';
import { LeaderboardTable } from './components';

const ACTIVE_TAB_INDEX = 1;

export function Leaderboard() {
  const { t } = useTranslation();
  const { userRatings, generalRatings, fetch } = useRatingsStore();
  const [tabIndex, setTabIndex] = useState(ACTIVE_TAB_INDEX);
  const isAuthorized = useIsAuthorized();
  const periods = ['yesterday', 'day', 'week', 'month'];

  useEffect(() => {
    fetch('day');
  }, []);

  const getEndOfTheDay = () => {
    const now = new Date();
    return new Date(
      Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 23, 59, 59, 999)
    );
  };

  const handleChange = async (index: number) => {
    setTabIndex(index);
    await fetch(periods[index]);
  };

  return (
    <Box
      sx={{
        width: { xs: '100%', sm: '600px' },
        height: 'calc(100vh - 114px)',
        m: '0 auto',
        mt: { xs: 1, sm: 2 },
      }}
    >
      <Paper
        sx={{
          p: 2,
          pb: 0,
          borderBottom: 0,
          borderRadius: '8px 8px 0 0',
        }}
      >
        <Stack direction="row" spacing={1} justifyContent="center">
          <Typography variant="h6" color="primary">
            {t('leaderboard.time_to_award')}:
          </Typography>
          <Typography variant="h6" color="primary">
            <Countdown endTime={getEndOfTheDay()} />
          </Typography>
        </Stack>

        <Tabs variant="fullWidth" value={tabIndex} onChange={(_, index) => handleChange(index)}>
          {periods.map((period) => (
            <Tab
              key={period}
              sx={{
                color: 'text.primary',
                minWidth: 0,
                fontSize: { xs: 14, sm: 16 },
              }}
              label={t(`leaderboard.btn_filter_${period}`)}
            />
          ))}
        </Tabs>

        {isAuthorized && !!generalRatings?.length && (
          <Stack justifyContent="space-between" direction="row" mt={1}>
            <Typography variant="body1" sx={{ fontSize: { xs: 14, sm: 16 } }}>
              {t('leaderboard.my_position')}: {userRatings?.position}
            </Typography>
            <Typography variant="body1" sx={{ fontSize: { xs: 14, sm: 16 } }}>
              {t('leaderboard.points_scored')}: {userRatings?.points}
            </Typography>
          </Stack>
        )}
      </Paper>
      <Box sx={{ overflow: 'auto', height: 'calc(100vh - 242px)', borderRadius: '0 0 8px 8px' }}>
        <LeaderboardTable />
      </Box>
    </Box>
  );
}
