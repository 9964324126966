import { TableCell, TableRow, Typography, Checkbox, TableSortLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StudySetCard } from 'stores/studySet';
import { STUDY_SET_CARDS_LIMIT } from 'constants/index';

interface TermsTableHeadProps {
  search: string;
  cards: StudySetCard[];
  selected: Set<string>;
  isOwner: boolean;
  isParticipant: boolean;
  sortConfig?: {
    key: keyof StudySetCard | 'additions';
    direction: 'asc' | 'desc' | 'none';
  };
  onSelectAll: (checked: boolean) => void;
  requestSort: (column: keyof StudySetCard) => void;
}

export function TermsTableHead({
  search,
  cards,
  selected,
  isOwner,
  isParticipant,
  sortConfig,
  onSelectAll,
  requestSort,
}: TermsTableHeadProps) {
  const { t } = useTranslation();
  const displayedСolumnTypes =
    isOwner || isParticipant
      ? ['terms', 'relatedTerms', 'additions', 'learningLevel']
      : ['terms', 'relatedTerms', 'additions'];

  const getSortDirection = (column: string) => {
    if (sortConfig?.key !== column) return 'asc';
    return sortConfig.direction === 'asc' ? 'desc' : 'asc';
  };

  return (
    <TableRow sx={{ p: 1, backgroundColor: 'background.paper' }}>
      {(isOwner || isParticipant) && (
        <TableCell padding="checkbox" sx={{ p: 1, pr: 0, backgroundColor: 'inherit' }}>
          <Checkbox
            id="check-all"
            indeterminate={selected.size > 0 && selected.size < cards.length}
            checked={cards.length > 0 && selected.size === cards.length}
            onChange={(e) => cards.length && onSelectAll(e.target.checked)}
          />
        </TableCell>
      )}
      {displayedСolumnTypes.map((column, index) => (
        <TableCell
          key={column}
          sortDirection={getSortDirection(column)}
          sx={{
            p: 1,
            pl: !index && !isOwner && !isParticipant ? 3 : 1,
            minWidth: 120,
            backgroundColor: 'inherit',
          }}
        >
          <TableSortLabel
            active={sortConfig?.key === column && sortConfig?.direction !== 'none'}
            direction={getSortDirection(column)}
            sx={{ fontSize: 16, fontWeight: 600 }}
            onClick={() => requestSort(column as keyof StudySetCard)}
          >
            {t(`set.table_titles.${column}`)}
            {!index && (
              <Typography variant="body2" sx={{ color: 'text.secondary', pl: 0.5 }}>
                {search ? `(${cards.length})` : `(${cards.length}/${STUDY_SET_CARDS_LIMIT})`}
              </Typography>
            )}
          </TableSortLabel>
        </TableCell>
      ))}
      {isOwner && <TableCell sx={{ backgroundColor: 'inherit' }} />}
    </TableRow>
  );
}
