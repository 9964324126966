import 'index.css';

import { GlobalStyles, ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { PropsWithChildren } from 'react';
import theme from 'theme';
import 'services/i18n';

export const AppProviders = ({ children }: PropsWithChildren) => (
  <ThemeProvider theme={theme}>
    <GlobalStyles
      styles={{
        '@keyframes mui-auto-fill': { from: { display: 'block' } },
        '@keyframes mui-auto-fill-cancel': { from: { display: 'block' } },
      }}
    />
    <CssBaseline />
    {children}
  </ThemeProvider>
);
