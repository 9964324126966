import { Fab } from '@mui/material';
import { ExpandLess } from '@mui/icons-material';
import { VirtuosoHandle } from 'react-virtuoso';
import { RefObject } from 'react';

interface ScrollToTopButtonProps {
  containerRef: RefObject<VirtuosoHandle>;
}

export function ScrollToTopButton({ containerRef }: ScrollToTopButtonProps) {
  const handleScrollToTop = () => {
    containerRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Fab
      size="small"
      aria-label="scroll-to-top-button"
      sx={{
        backgroundColor: 'secondary.light',
        position: 'absolute',
        bottom: 68,
        right: { xs: 16, sm: 32 },
        '&:hover': {
          backgroundColor: 'secondary.main',
        },
      }}
      onClick={handleScrollToTop}
    >
      <ExpandLess color="primary" />
    </Fab>
  );
}
