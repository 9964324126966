import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { styled } from '@mui/system';

export const DangerToolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.secondary,
    color: theme.palette.error.dark,
    border: `2px solid ${theme.palette.error.dark}`,
    fontSize: '16px',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.error.dark,
  },
}));
