import { House, AddLink, Leaderboard, Laptop, SmartToy } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useAuthStore, useIsAuthorized } from 'stores/auth';
import { ROUTES } from 'routes/routes.config';
import { MOBILE_PLATFORMS, ARNIE_ID } from 'constants/index';
import { BottomNavbar } from './partials/BottomNavbar';
import { TopNavbar } from './partials/TopNavbar';

export function Navbar() {
  const { t } = useTranslation();
  const theme = useTheme();
  const appLocation = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { initData, platform, openLink } = window.Telegram.WebApp;
  const isAuthorized = useIsAuthorized();
  const { user } = useAuthStore();

  const navItems = [
    {
      label: t('navbar.full_screen'),
      icon: <Laptop />,
      isShown: !!initData && !MOBILE_PLATFORMS.includes(platform),
      handler: () => {
        openLink(`${location.origin}/tlogin?${initData}&next=${appLocation.pathname}`);
      },
    },
    {
      label: t('navbar.profile'),
      path: ROUTES.PROFILE(user?.id),
      icon: <House />,
      isShown: isAuthorized,
    },
    {
      label: t('navbar.arnie'),
      path: ROUTES.PROFILE(ARNIE_ID),
      icon: <SmartToy />,
      isShown: isAuthorized,
    },
    {
      label: t('navbar.ratings'),
      path: ROUTES.LEADERBOARD,
      icon: <Leaderboard />,
      isShown: true,
    },
    {
      label: t('navbar.join'),
      icon: <AddLink />,
      isShown: !isAuthorized,
      handler: () => {
        window.location.replace('tg://resolve?domain=ArnieEnglishBot');
      },
    },
  ];

  const shownNavItems = navItems.filter((item) => item.isShown);

  return (
    <Box component="section" sx={{ px: '24px' }}>
      {isMobile ? (
        <BottomNavbar navItems={shownNavItems} />
      ) : (
        <TopNavbar navItems={shownNavItems} />
      )}
    </Box>
  );
}
