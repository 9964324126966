import { Paper, Stack, Box, Typography, IconButton, Button } from '@mui/material';
import { Delete, EditOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface ToolbarBackProps {
  selected: Set<string>;
  isOwner: boolean;
  onChangeLearningLevel: () => void;
  onEdit: () => void;
  onDelete: () => void;
}

export function ToolbarBack({
  selected,
  isOwner,
  onChangeLearningLevel,
  onEdit,
  onDelete,
}: ToolbarBackProps) {
  const { t } = useTranslation();

  return (
    <Paper key="back" sx={{ px: 2, py: 1, display: 'flex', minHeight: 58, alignItems: 'center' }}>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography sx={{ display: { xs: 'none', sm: 'inline' } }}>
            {t('set.toolbar.selected')}
          </Typography>
          <Typography>({selected.size})</Typography>
          <Button variant="outlined" size="small" onClick={onChangeLearningLevel}>
            {t('set.toolbar.change_progress')}
          </Button>
        </Stack>
        {isOwner && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              size="small"
              sx={{ border: '1px solid', borderColor: 'primary.main', mr: 1 }}
              onClick={onEdit}
            >
              <EditOutlined color="primary" />
            </IconButton>
            <IconButton
              size="small"
              sx={{ border: '1px solid', borderColor: 'error.main' }}
              onClick={onDelete}
            >
              <Delete color="error" />
            </IconButton>
          </Box>
        )}
      </Box>
    </Paper>
  );
}
