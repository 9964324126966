import { graphql } from 'services/graphql';

export const getRatingsQuery = graphql`
  query userRatings($period: String!) {
    userRatings(period: $period) {
      items {
        userId
        firstName
        lastName
        points
      }
      currentUserRating {
        userId
        points
        position
      }
    }
  }
`;
