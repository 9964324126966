import { client } from 'services/graphql';
import { UpdateIntervalTrainingSettings, UpdateUserSettings } from 'stores/user';
import { getUserInfoQuery, getUserSetsQuery, getIntervalTrainingSettingsQuery } from './queries';
import { updateIntervalTrainingSettingsMutation, updateUserSettingsMutation } from './mutations';

export const getUserInfo = async (userId: string) =>
  await client.request(getUserInfoQuery, { userId });

export const getUserSets = async (userId: string) =>
  await client.request(getUserSetsQuery, { userId });

export const getIntervalTrainingSettings = async () =>
  await client.request(getIntervalTrainingSettingsQuery);

export const updateIntervalTrainingSettings = async ({
  trainingEnabled,
  interval,
  startTime,
  endTime,
  timezoneOffset,
}: UpdateIntervalTrainingSettings) =>
  await client.request(updateIntervalTrainingSettingsMutation, {
    trainingEnabled,
    interval,
    startTime,
    endTime,
    timezoneOffset,
  });

export const updateUserSettings = async ({ language }: UpdateUserSettings) =>
  await client.request(updateUserSettingsMutation, { language });
