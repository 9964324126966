import { graphql } from 'services/graphql';

export const createStudySetMutation = graphql`
  mutation createUserSet($name: String!, $visibility: String!) {
    createUserSet(name: $name, visibility: $visibility) {
      id
      errors {
        code
        message
        field
      }
    }
  }
`;

export const activateStudySetMutation = graphql`
  mutation setUserSetActive($userSetId: String!) {
    setUserSetActive(userSetId: $userSetId) {
      isSuccess
      errors {
        code
        message
        field
      }
    }
  }
`;

export const updateStudySetMutation = graphql`
  mutation updateUserSet(
    $userSetId: String!
    $title: String
    $description: String
    $visibility: String
    $level: String
  ) {
    updateUserSet(
      userSetId: $userSetId
      title: $title
      description: $description
      visibility: $visibility
      level: $level
    ) {
      isSuccess
      errors {
        code
        message
        field
      }
    }
  }
`;

export const deleteStudySetMutation = graphql`
  mutation deleteSet($userSetId: String!) {
    deleteSet(userSetId: $userSetId) {
      isSuccess
      errors {
        code
        message
      }
    }
  }
`;

export const removeStudySetMutation = graphql`
  mutation removeSet($userSetId: String!) {
    removeSet(userSetId: $userSetId) {
      isSuccess
      errors {
        code
        message
      }
    }
  }
`;

export const addStudySetMutation = graphql`
  mutation addSet($userSetId: String!) {
    addSet(userSetId: $userSetId) {
      id
      errors {
        code
      }
    }
  }
`;
