import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCardStore } from 'stores/card';
import { StudySetRouteParams } from 'routes/routes.types';

interface DeleteCardsDialogProps {
  open: boolean;
  ids: string[];
  onClose: () => void;
}

export function DeleteCardsDialog({ open, ids, onClose }: DeleteCardsDialogProps) {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { setId } = useParams() as StudySetRouteParams;
  const { delete: deleteCards } = useCardStore();

  const handleSubmit = async () => {
    setLoading(true);
    await deleteCards(setId, ids);
    setLoading(false);
    onClose();
  };

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>{t('dialog_delete_card.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {ids.length > 1
            ? t('dialog_delete_card.text_multiple', { num: ids.length })
            : t('dialog_delete_card.text')}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: 3, pt: 0 }}>
        <Button variant="contained" color="secondary" onClick={onClose}>
          {t('common.close')}
        </Button>
        <LoadingButton loading={loading} color="error" variant="contained" onClick={handleSubmit}>
          {t('common.delete')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
